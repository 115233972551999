import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'

import get from 'lodash/get'

import { useSnackbar } from 'notistack'
import { Trans, defineMessage } from '@lingui/macro'

const useStyles = makeStyles((theme) => ({
  controls: {
    textAlign: 'right',
  },
  button: {
    marginLeft: theme.spacing(1),
  },
}))

const labels = {
  previous: defineMessage({ id: 'ui.wizard.controls.previousButton.label ' }),
  next: defineMessage({ id: 'ui.wizard.controls.nextButton.label ' }),
  submit: defineMessage({ id: 'ui.wizard.controls.submitButton.label ' }),
}

export function WizardControls({ useWizard, onSubmit }) {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const { handleNext, handleBack, isFirstStep, isLastStep } = useWizard()
  const displayCancel = !isFirstStep

  return (
    <div className={classes.controls}>
      {!displayCancel ? null : (
        <Button onClick={handleBack} className={classes.button}>
          <Trans id={labels.previous.id} />
        </Button>
      )}
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={async (e) => {
          e.nativeEvent.stopImmediatePropagation()

          if (!onSubmit) {
            return handleNext()
          }

          const result = await onSubmit()

          if (result) {
            return handleNext()
          }

          if (result === false) {
            enqueueSnackbar('error', {
              variant: 'error',
              autoHideDuration: 3000,
            })
          }
        }}
      >
        <Trans id={get(isLastStep ? labels.submit : labels.next, 'id')} />
      </Button>
    </div>
  )
}
