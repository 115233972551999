import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useCallback,
  useEffect
} from 'react'
import { useRouter } from 'next/router'
import get from 'lodash/get'

export const wizardContextBuilder = ({ steps }) => {
  const initialState = {
    activeStep: 0,
    steps: steps.map(step => ({
      ...step,
      isValid: false
    }))
  }

  const WizardContext = createContext(initialState)

  const useWizard = () => useContext(WizardContext)

  const WizardProvider = ({ activeStep, children }) => {
    const router = useRouter()
    const [step, setActiveStep] = useState(activeStep || 0)
    const [isStepValid, setStepValid] = useState(activeStep.isValid || false)
    const isFirstStep = step === 0
    const isLastStep = step === steps.length - 1

    const handleBack = useCallback(() => {
      if (!isFirstStep) {
        setActiveStep(step - 1)
      }
    }, [isFirstStep, step])

    const handleNext = useCallback(() => {
      if (!isLastStep) {
        setActiveStep(step + 1)
      }
    }, [isLastStep, step])

    const value = useMemo(
      () => ({
        ...initialState,
        activeStep: step,
        setActiveStep,
        isFirstStep,
        isLastStep,
        isStepValid,
        setStepValid,
        handleBack,
        handleNext
      }),
      [step, isFirstStep, isLastStep, isStepValid, handleBack, handleNext]
    )

    useEffect(() => {
      const { path, href } = get(steps, `[${step}]`)

      if (path === router.route) {
        return
      }

      router.push(href, undefined, { shallow: true, scroll: true })
    }, [step, router])

    useEffect(() => {
      if (step < steps.length - 1) {
        const { href } = get(steps, `[${step + 1}]`)

        router.prefetch(href)
      }
    }, [step, router])

    return (
      <WizardContext.Provider value={value}>{children}</WizardContext.Provider>
    )
  }

  return {
    useWizard,
    WizardProvider
  }
}
