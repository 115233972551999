import React, { forwardRef, useState } from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import merge from 'lodash/merge'
import attempt from 'lodash/attempt'

export function FormCheckboxes(
  {
    name,
    label,
    choices = [],
    controlled = false,
    defaultValue = {},
    value,
    required = false,
    error = false,
    onChange,
  },
  ref,
) {
  const defaultState = {}
  choices.forEach(({ value }) => {
    defaultState[value] = false
  })

  const [state, setState] = useState(
    merge(defaultState, controlled ? value : defaultValue),
  )

  const handleChange = (event) => {
    const { name, checked } = event.target
    setState({
      ...state,
      [name]: checked,
    })
    attempt(onChange, event)
  }

  const hasError = !!error

  return (
    <FormControl
      component="fieldset"
      required={required}
      error={hasError}
      fullWidth
      ref={ref}
    >
      <FormLabel component="legend">{label}</FormLabel>
      {choices.map(({ value, label }, i) => (
        <FormControlLabel
          key={`${name}-${i}`}
          label={label}
          control={
            <Checkbox
              checked={state[value]}
              onChange={handleChange}
              name={value}
              color="primary"
            />
          }
        />
      ))}
      {!hasError ? null : <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}

export default forwardRef(FormCheckboxes)
