import get from 'lodash/get'

export const getLangInfo = (lang = {}, property = 'code') => get(lang, property)

export const getLangFlag = (lang = {}) => getLangInfo(lang, 'flag')

export const getLangCode = (lang = {}) => getLangInfo(lang)

export const getLangName = (lang = {}) => getLangInfo(lang, 'name')

export const findLang = (lid, langs = []) => langs.find(lang => lang.lid === lid)
