import { createContext, useContext, useReducer } from 'react'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'

const initialState = {
  cardHolder: {
    value: '',
    error: false,
  },
  cardNumber: {
    complete: false,
    error: false,
  },
  cardExpiry: {
    complete: false,
    error: false,
  },
  cardCvc: {
    complete: false,
    error: false,
  },
  filled: false,
  hasError: false,
}

const SET_CARD_HOLDER = 'cardHolder'
const SET_CARD_NUMBER = 'cardNumber'
const SET_CARD_EXPIRY = 'cardExpiry'
const SET_CARD_CVC = 'cardCvc'

const isFilled = (state) =>
  !isEmpty(state.cardHolder.value) &&
  state.cardNumber.complete &&
  state.cardExpiry.complete &&
  state.cardCvc.complete

const hasError = (state) =>
  isEmpty(state.cardHolder.value) ||
  get(state, 'cardNumber.complete', false) ||
  get(state, 'cardExpiry.complete', false) ||
  get(state, 'cardCvc.complete', false)

const cartStatus = (state) => ({
  ...state,
  filled: isFilled(state),
  hasError: hasError(state),
})
const reducer = (state, action) => {
  const { type, payload } = action

  switch (type) {
    case SET_CARD_HOLDER:
    case SET_CARD_NUMBER:
    case SET_CARD_EXPIRY:
    case SET_CARD_CVC:
      return cartStatus({
        ...state,
        [type]: payload,
      })
  }

  return state
}

const CardContext = createContext()

export const useCard = () => useContext(CardContext)

export const isCartFilled = () => get(useContext(CardContext), 'filled')

export function CardProvider({ children }) {
  return (
    <CardContext.Provider value={useReducer(reducer, initialState)}>
      {children}
    </CardContext.Provider>
  )
}
