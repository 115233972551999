import { createSlice } from '@reduxjs/toolkit'
import pick from 'lodash/pick'

const initialState = {
  messages: null,
  options: null,
  pack: null,
  fileSupport: []
}

const pricingSlice = createSlice({
  name: 'pricing',
  initialState,
  reducers: {
    setPricing (state, action) {
      const options = pick.apply(null, [action.payload].concat(Object.keys(initialState)))

      let name

      for (name in options) {
        state[name] = options[name]
      }
    }
  }
})

const { actions, reducer } = pricingSlice

export const { setPricing } = actions

export default reducer
