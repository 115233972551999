import React from 'react'

import Button from '@material-ui/core/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'

import { Trans } from '@lingui/macro'

export function ConfirmationDialog({
  title,
  children,
  cancelLabel,
  okLabel,
  onClose,
  open,
  ...other
}) {
  const handleCancel = () => {
    onClose(false)
  }

  const handleOk = () => {
    onClose(true)
  }

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
    >
      <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="primary">
          {cancelLabel || <Trans id="ui.dialog.cancel.label" />}
        </Button>
        <Button onClick={handleOk} color="primary">
          {okLabel || <Trans id="ui.dialog.ok.label" />}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationDialog
