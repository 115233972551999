import get from 'lodash/get'
import replace from 'lodash/replace'
import property from 'lodash/property'

import {
  STANDARD_MESSAGE,
  SEMI_STANDARD_MESSAGE,
  CUSTOM_MESSAGE,

  MESSAGE_SELECTION_TEXT,
  MESSAGE_SELECTION_FIRM,
  MESSAGE_SELECTION_BLOCK,
  MESSAGE_SELECTION_TRANSLATION,
  MESSAGE_SELECTION_ORIGIN
} from '@slc/constants'

const injectFirmInText = (firm, text) => replace(text, /\{[^\}]+}/, `[${firm}]`)

export const getMessageLangSelection = ({ lid, category, selection }) => {
  if (!lid || !category || !selection) {
    return null
  }

  switch (category) {
    case STANDARD_MESSAGE:
    case SEMI_STANDARD_MESSAGE: {
      const text = selection.find(part => part.k === MESSAGE_SELECTION_TEXT && part.lid === lid)

      return [get(text, 'v')]
    }

    case CUSTOM_MESSAGE: {
      const $blocks = selection.find(part => part.k === MESSAGE_SELECTION_BLOCK)

      return get($blocks, 'v', [])
        .filter(part => get(part, 'lang.lid') === lid)
        .filter(part => get(part, 'text', '').length > 0)
        .map(property('text'))
    }
  }
}

export const getMessageSelection = ({ lid, category, selection }) => {
  if (!lid || !category || !selection) {
    return null
  }

  const blocks = getMessageLangSelection({ lid, category, selection })

  switch (category) {
    case STANDARD_MESSAGE: {
      return {
        category,
        blocks
      }
    }

    case SEMI_STANDARD_MESSAGE: {
      const [text] = blocks
      const firm = get(selection.find(part => part.k === MESSAGE_SELECTION_FIRM), 'v')

      return {
        category,
        blocks: [injectFirmInText(firm, text)]
      }
    }

    case CUSTOM_MESSAGE: {
      if (blocks.length) {
        return {
          category,
          blocks,
          translated: false
        }
      }

      const translation = selection.find(part => part.k === MESSAGE_SELECTION_TRANSLATION)

      if (!translation.v.includes(lid)) {
        return null // should never occurs
      }

      const origin = selection.find(part => part.k === MESSAGE_SELECTION_ORIGIN)

      if (!origin) {
        return {
          category,
          blocks: [],
          translated: true
        }
      }

      const isTranslatedFromOtherLang = get(origin, 'v.lang') === lid

      if (!isTranslatedFromOtherLang) {
        return {
          category,
          blocks: [],
          translated: true
        }
      }

      return {
        category,
        blocks: [get(origin, 'v.text')],
        translated: true,
        origin: get(origin, 'v.from')
      }
    }
  }
}
