import React, {
  createContext,
  useContext,
  useState,
  useEffect
} from 'react'

import get from 'lodash/get'

import { getCustomerInfos } from '@slc/api-generic'
import { PageLoader } from '@slc/ui'

const initialState = {
  cid: null,
  type: null,
  paymentTypes: [],
  pricing: null,
  balance: -1
}

const CustomerContext = createContext(initialState)

export const useCid = () => get(useContext(CustomerContext), 'cid', null)
export const usePaymentTypes = () => get(useContext(CustomerContext), 'paymentTypes', [])
export const useBalance = () => get(useContext(CustomerContext), 'balance', -1)
export const usePricing = () => get(useContext(CustomerContext), 'pricing', null)

export const CustomerProvider = ({ children }) => {
  const [infos, setInfos] = useState()

  useEffect(() => {
    if (!infos) {
      getCustomerInfos()
        .then(setInfos)
        .catch(e => {
          console.log(e)
        })
    }
  }, [])

  if (!infos) {
    return <PageLoader />
  }

  return (
    <CustomerContext.Provider value={infos}>{children}</CustomerContext.Provider>
  )
}
