import React, { forwardRef } from 'react'
import { patterns } from '@slc/config'
import FormInput from '../Input'

export function FormInputEmail(props, ref) {
  return <FormInput ref={ref} {...props} autoComplete="username" />
}

FormInputEmail.validation = {
  pattern: patterns.email,
}

export default forwardRef(FormInputEmail)
