export const TRANSLATION_WAITING_PRODUCTION_MESSAGE_STATUS = 'translation_waiting'
export const TRANSLATION_RETAKE_PRODUCTION_MESSAGE_STATUS = 'translation_retake'
export const TRANSLATION_VALIDATION_PRODUCTION_MESSAGE_STATUS = 'translation_validation'
export const TRANSLATION_VALIDATED_PRODUCTION_MESSAGE_STATUS = 'translation_validated'

export const VOICE_WAITING_PRODUCTION_MESSAGE_STATUS = 'voice_waiting'
export const VOICE_RETAKE_PRODUCTION_MESSAGE_STATUS = 'voice_retake'
export const VOICE_VALIDATION_PRODUCTION_MESSAGE_STATUS = 'voice_validation'
export const VOICE_VALIDATED_PRODUCTION_MESSAGE_STATUS = 'voice_validated'

export const PRODUCTION_MESSAGE_STATUSES = [
  TRANSLATION_WAITING_PRODUCTION_MESSAGE_STATUS,
  TRANSLATION_VALIDATION_PRODUCTION_MESSAGE_STATUS,
  TRANSLATION_VALIDATED_PRODUCTION_MESSAGE_STATUS,
  TRANSLATION_RETAKE_PRODUCTION_MESSAGE_STATUS,

  VOICE_WAITING_PRODUCTION_MESSAGE_STATUS,
  VOICE_VALIDATION_PRODUCTION_MESSAGE_STATUS,
  VOICE_VALIDATED_PRODUCTION_MESSAGE_STATUS,
  VOICE_RETAKE_PRODUCTION_MESSAGE_STATUS
]

export const WAITING_MESSAGE_STATUS = 'waiting'
export const IN_PRODUCTION_MESSAGE_STATUS = 'in_production'
export const RETAKE_MESSAGE_STATUS = 'retake'
export const COMPLETED_MESSAGE_STATUS = 'completed'
export const CANCELLED_MESSAGE_STATUS = 'cancelled'

export const MESSAGE_STATUSES = [
  WAITING_MESSAGE_STATUS,
  IN_PRODUCTION_MESSAGE_STATUS,
  RETAKE_MESSAGE_STATUS,
  COMPLETED_MESSAGE_STATUS,
  CANCELLED_MESSAGE_STATUS
]

export const CANCELLED_ORDER_PRODUCTION_STATUS = 'cancelled'
export const WAITING_ORDER_PRODUCTION_STATUS = 'waiting'
export const PRODUCTION_ORDER_PRODUCTION_STATUS = 'production'
export const DELIVERED_ORDER_PRODUCTION_STATUS = 'delivered'

export const ORDER_PRODUCTION_STATUSES = [
  CANCELLED_ORDER_PRODUCTION_STATUS,
  WAITING_ORDER_PRODUCTION_STATUS,
  PRODUCTION_ORDER_PRODUCTION_STATUS,
  DELIVERED_ORDER_PRODUCTION_STATUS,

]
