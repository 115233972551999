import { makeStyles } from '@material-ui/core/styles'
import { RadioButtons, Loader, LinkButton } from '@slc/ui'

import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import has from 'lodash/has'

import { CREDIT_PAYMENT } from '@slc/constants'

import { links, getCustomerLinkHref } from '@slc/app-links'

import property from 'lodash/property'
import { Trans } from '@lingui/macro'
import { useBalance, usePaymentTypes } from '@slc/context'
import { data } from './config'
import { activatePaymentTypes } from './filter'

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  creditContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  creditInfos: {
    display: 'flex',
    flexDirection: 'column',
    paddingRight: theme.spacing(3),
  },
  creditLabel: {},
  creditBalance: {
    fontSize: '0.9rem',
  },
}))

export function CreditLabel({ choice }) {
  const classes = useStyles()
  const { balance } = choice

  return (
    <div className={classes.creditContent}>
      <div className={classes.creditInfos}>
        <span className={classes.creditLabel}>{choice.label}</span>
        <span className={classes.creditBalance}>
          <Trans id="ui.paymentForm.credit.balance" values={{ balance }}>
            Votre solde s'élève à {balance} crédits.
          </Trans>
        </span>
      </div>
      {!choice.enabled && (
        <div>
          <LinkButton
            href={getCustomerLinkHref(links.customer.topup)}
            variant="outlined"
          >
            <Trans id={links.customer.topup.title} />
          </LinkButton>
        </div>
      )}
    </div>
  )
}

export function PaymentRadioButtonRenderer({
  name,
  defaultValue,
  disabled,
  classes,
  handleChange,
  radioClassName,
  choices,
}) {
  return (
    <RadioGroup
      aria-label={name}
      name={name}
      onChange={handleChange}
      className={radioClassName}
    >
      {choices.map((choice, i) => (
        <FormControlLabel
          key={`${name}-${i}`}
          value={choice.value}
          checked={choice.value === defaultValue}
          control={<Radio color="primary" disabled={disabled} />}
          disabled={has(choice, 'enabled') && !choice.enabled}
          label={
            choice.value !== CREDIT_PAYMENT
              ? choice.label
              : <CreditLabel choice={choice} />
          }
          classes={{
            root: classes.formControlLabel,
          }}
        />
      ))}
    </RadioGroup>
  )
}

export function PaymentForm({
  amount,
  selection,
  onChange,
  deniedPaymentTypes = [],
}) {
  const classes = useStyles()

  const paymentTypes = usePaymentTypes().filter(
    ({ name }) => !deniedPaymentTypes.includes(name),
  )
  const balance = useBalance()

  const types = paymentTypes.map(property('name'))
  const choices = activatePaymentTypes({
    choices: data.filter(({ value }) => types.includes(value)),
    amount,
    balance,
  })

  return (
    <div className={classes.content}>
      <RadioButtons
        name="payment"
        label={<Trans id="ui.cart.payment.form.title" />}
        choices={choices}
        defaultValue={selection}
        onChange={(e) => {
          onChange(e.currentTarget.value)
        }}
        required
        renderer={PaymentRadioButtonRenderer}
      />
      {!paymentTypes.length ? <Loader /> : null}
    </div>
  )
}
