import { defineMessage } from '@lingui/macro'

const altText = defineMessage({
  id: 'ui.logo.altText',
  message: 'STUDIO-LOWCOST - votre attente téléphonique à prix discount',
})

const WIDTH = 202
const HEIGHT = 100

export function Logo({ width = WIDTH, height = HEIGHT, ratio = 1 }) {
  return (
    <img
      src={`${process.env.NEXT_PUBLIC_APP_URL}/images/logo_big.png`}
      height={ratio !== 1 ? Math.round(ratio * height) : height}
      width={ratio !== 1 ? Math.round(ratio * width) : width}
      alt={altText.message}
    />
  )
}
