import React, { forwardRef } from 'react'
import { patterns } from '@slc/config'
import FormInput from '../Input'

export function FormInputPhone(props, ref) {
  return <FormInput ref={ref} {...props} />
}

FormInputPhone.validation = {
  pattern: patterns.phone,
}

export default forwardRef(FormInputPhone)
