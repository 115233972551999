import keys from 'lodash/keys'
import zipObject from 'lodash/zipObject'
import isEmpty from 'lodash/isEmpty'
import isNumber from 'lodash/isNumber'
import isBoolean from 'lodash/isBoolean'

export const validate = async ({ validation, data = {}, ...options }) => {
  const $keys = keys(data).filter(name => {
    const v = data[name]
    if (isNumber(v) || isBoolean(v)) {
      return true
    }

    return !isEmpty(data[name])
  })

  const $data = zipObject(
    $keys,
    $keys.map(key => data[key])
  )

  if (!validation) {
    return {
      ok: true,
      values: data,
      validation: false
    }
  }

  const result = await validation($data)

  if (result) {
    return {
      ok: true,
      values: data
    }
  }

  const errors = {}

  validation.errors.forEach(({ keyword, params, instancePath, schemaPath, ...o }) => {
    const prop = instancePath.length ? instancePath.substring(1) : params.missingProperty

    errors[prop] = `error.${keyword}`
  })

  console.log('validation errors', validation.errors)

  return {
    ok: false,
    errors,
    values: data
  }
}
