import React, { createContext, useState, useEffect } from 'react'

import property from 'lodash/property'
import isEmpty from 'lodash/isEmpty'

import { countryInfos as getCountryInfos } from '@slc/api-generic'

import { useDispatch, useSelector } from 'react-redux'
import { country } from '@slc/store'

const initialState = {
  currency: null,
  tax: null,
  lang: [],
}

const CountryContext = createContext(initialState)

// export const useCountryInfos = () => useContext(CountryContext)

export const useSite = () => useSelector(property('country.site')) || null
export const useCurrency = () => useSelector(property('country.currency')) || []
export const useTaxInfos = () => useSelector(property('country.tax')) || []
export const useLangs = () => useSelector(property('country.lang')) || []
export const useMobileProviders = () =>
  useSelector(property('country.mobileProviders')) || []

export function CountryProvider({ lang, countryInfos, children }) {
  console.log('CountryProvider', { lang, countryInfos })
  const [infos, setInfos] = useState(countryInfos)
  const dispatch = useDispatch()

  useEffect(() => {
    if (isEmpty(infos)) {
      console.log('CountryProvider - getCountryInfos')
      getCountryInfos({ lang })
        .then(setInfos)
        .catch((e) => {
          console.log(e)
        })
    }
  }, [])

  useEffect(() => {
    console.log('country.setInfos ?')
    if (!isEmpty(infos)) {
      console.log('country.setInfos !')
      dispatch(country.setInfos(infos))
    }
  }, [infos])

  return (
    <CountryContext.Provider value={infos}>{children}</CountryContext.Provider>
  )
}
