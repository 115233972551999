import React, { forwardRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import attempt from 'lodash/attempt'
import filter from 'lodash/filter'
import first from 'lodash/first'
import get from 'lodash/get'
import has from 'lodash/has'

import { getLabel } from '../../../utils/label'

const useStyles = makeStyles((theme) => ({
  formControl: {},
  menuItem: {
    textTransform: 'capitalize',
  },
}))

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

export function FormSelect(
  {
    name,
    label,
    choices = [],
    controlled = false,
    defaultValue = '',
    value = '',
    required = false,
    error = false,
    fullWidth = false,
    onChange,
    ...others
  },
  ref,
) {
  const classes = useStyles()
  const [state, setState] = useState({
    value: controlled ? value : defaultValue,
  })

  const handleChange = (event) => {
    const { value } = event.target
    setState({
      ...state,
      // value: controlled ? value : value.value
      value,
    })
    attempt(onChange, value)
  }

  const selection = get(first(filter(choices, { value: state.value })), 'value')

  const props = {
    value: selection || '',
  }

  const hasError = !!error

  return (
    <FormControl
      variant="outlined"
      required={required}
      error={hasError}
      className={classes.formControl}
      fullWidth={fullWidth}
    >
      <InputLabel htmlFor={name}>{getLabel(label)}</InputLabel>
      <Select
        ref={ref}
        label={getLabel(label)}
        defaultValue={state.value}
        {...props}
        onChange={handleChange}
        MenuProps={MenuProps}
        inputProps={{
          name,
          id: name,
        }}
      >
        {choices.map((choice, i) => (
          <MenuItem value={choice.value} key={`${name}-${i}`}>
            <div className={classes.menuItem}>{getLabel(choice.label)}</div>
          </MenuItem>
        ))}
      </Select>
      {!hasError ? null : <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}

export default forwardRef(FormSelect)
