import React, { forwardRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

import attempt from 'lodash/attempt'

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
}))

export function FormInputPassword(
  {
    name,
    label,
    controlled = false,
    defaultValue = undefined,
    value,
    required = false,
    error,
    helperText = '',
    onChange,
    disabled = false,
    fullWidth = false,
    autoComplete = 'new-password', // 'current-password'
    ...others
  },
  ref,
) {
  const classes = useStyles()
  const [state, setState] = useState({
    value: controlled ? value : defaultValue,
    showPassword: false,
  })

  const handleChange = (event) => {
    const { value } = event.target

    setState({
      ...state,
      value,
    })
    attempt(onChange, event)
  }

  const handleClickShowPassword = () => {
    setState({
      ...state,
      showPassword: !state.showPassword,
    })
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  return (
    <FormControl className={classes.formControl} fullWidth={fullWidth}>
      <TextField
        id={name}
        label={label}
        type={state.showPassword ? 'text' : 'password'}
        defaultValue={state.value}
        onChange={handleChange}
        variant="outlined"
        required={required}
        error={!!error}
        helperText={error || helperText}
        disabled={disabled}
        fullWidth={fullWidth}
        autoComplete={autoComplete}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {state.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...others}
      />
    </FormControl>
  )
}

export default forwardRef(FormInputPassword)
