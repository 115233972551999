import { createTheme, responsiveFontSizes } from '@material-ui/core/styles'
import red from '@material-ui/core/colors/red'
import orange from '@material-ui/core/colors/orange'

const spacing = 8
const primary = '#feb333'
const secondary = '#5885AF'

export const colors = {
  primary: {
    // main: '#556cd6'
    main: primary,
  },
  secondary: {
    // main: '#19857b',
    main: secondary,
  },
  error: {
    main: red.A400,
  },
  background: {
    default: '#fff',
  },
  input: {
    main: '#adabab',
    disabled: '#ededed',
  },
  border: {
    main: primary,
    disabled: '#dcdcdc',
  },
}

// #dee0e6
// Create a theme instance.
// See http://localhost:3333/customization/default-theme/?expand-path=$.typography
// https://material-ui.com/customization/default-theme/#explore
export const theme = createTheme({
  palette: {
    type: 'light',
    ...colors,
  },
  typography: {
    // fontSize: 10,
    /* https://type-scale.com/ 3rd M */
    h1: {
      fontSize: '3.052rem',
      fontWeight: 300,
    },
    h2: {
      fontSize: '2.441rem',
      fontWeight: 300,
    },
    h3: {
      fontSize: '1.953rem',
      fontWeight: 300,
    },
    h4: {
      fontSize: '1.563rem',
      fontWeight: 300,
    },
    h5: {
      fontSize: '1.25rem',
      fontWeight: 300,
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 300,
    },
    body1: {
      fontWeight: 200,
    },
    body2: {
      fontWeight: 200,
    },
    subtitle1: {
      fontWeight: 200,
    },
    subtitle2: {
      fontWeight: 200,
    },
    button: {
      fontWeight: 200,
    },
    caption: {
      fontWeight: 200,
    },
    overline: {
      fontWeight: 200,
    },
  },
  shape: {
    borderRadius: 8,
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          // backgroundColor: '#f1f1f1'
          backgroundColor: '#fff9e9',
        },
        a: {
          textDecoration: 'none',
        },
      },
    },
    MuiLink: {
      root: {
        cursor: 'pointer',
      },
    },
    MuiPaper: {
      root: {
        transition: 'border-color 0.3s cubic-bezier(.47,1,.41,.8)',

        '&:hover, &:focus': {
          borderColor: colors.border.main,
        },
      },

      outlined: {
        borderColor: colors.border.disabled,
        padding: 2 * spacing,

        '&:hover, &:focus': {
          borderColor: colors.border.main,
        },
      },
    },
    MuiCard: {
      root: {
        transition: 'border-color 0.3s cubic-bezier(.47,1,.41,.8)',
        borderColor: colors.input.main,
        borderStyle: 'solid',
        borderWidth: 1,

        '&:hover, &:focus': {
          borderColor: orange[300],
        },
      },
    },
    MuiCardActions: {
      root: {
        justifyContent: 'flex-end',
      },
    },
    MuiFormControl: {
      root: {
        marginBottom: 4,
      },
    },
    MuiInputBase: {
      input: {
        // backgroundColor: '#fff'
      },
      root: {
        '&$disabled': {
          backgroundColor: colors.input.disabled,
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        '&$disabled': {
          borderColor: colors.input.disabled,
        },
      },
      input: {
        padding: '10px',
      },
      multiline: {
        padding: '10px',
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: 'translate(14px, 12px) scale(1)',
      },
    },
    '.grecaptcha-badge': {
      bottom: 12,
    },
    MuiPrivateRadioButtonIcon: {
      root: {
        '&$disabled': {
          backgroundColor: colors.input.disabled,
        },
      },
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true, // No more ripple, on the whole application
    },
    MuiAppBar: {
      elevation: 0,
    },
    MuiPaper: {
      elevation: 0,
    },
    MuiLink: {
      cursor: 'pointer',
    },
    MuiCard: {
      elevation: 0,
    },
    MuiCardHeader: {
      // disableTypography: true
    },
  },
})

// export default responsiveFontSizes(theme)
// export default theme
