import isEmpty from 'lodash/isEmpty'

import { locale as localeConfig } from '@slc/config'

const { defaultLocale } = localeConfig

export const i18nActivate = async (i18n, locale = defaultLocale, messages = {}) => {
  if (!isEmpty(messages)) {
    i18n.load(locale, messages)
  } else {
    const $messages = await loadLocaleMessage(locale)

    if (!$messages) {
      console.log('Failed to load message for locale' + locale)
      return
    }

    i18n.load(locale, $messages.messages)
  }

  const plurals = await loadPlurals(locale)
  i18n.loadLocaleData(locale, { plurals })
  i18n.activate(locale)
}

export const loadPlurals = async locale => {
  try {
    const plurals = await import('make-plural/plurals')

    return plurals[locale]
  } catch (e) {
    return null
  }
}

export const loadLocaleMessage = async (locale = defaultLocale) => {
  const messages = await import(/* webpackChunkName: "slc-i18n-locale" */`@i18n/${locale}.json`)

  return {
    messages: messages.default
  }
}
