import {
  STANDARD_MESSAGE,
  SEMI_STANDARD_MESSAGE,
  CUSTOM_MESSAGE
} from '@slc/constants'

import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

const getMessagePricing = (type, messages) =>
  get(messages.find(({ name }) => name === type), 'pricing')

export const getStandardMessagePricing = messages => getMessagePricing(STANDARD_MESSAGE, messages)
export const getSemiStandardMessagePricing = messages => getMessagePricing(SEMI_STANDARD_MESSAGE, messages)
export const getCustomMessagePricing = messages => getMessagePricing(CUSTOM_MESSAGE, messages)

export const getMessageStarterPrices = pricing => {
  const messages = get(pricing, 'messages', [])

  if (isEmpty(messages)) {
    return null
  }

  const currency = get(pricing, 'currency.code')

  return {
    [STANDARD_MESSAGE]: {
      value: get(standardMessagePricingCall(pricing), 'base'),
      currency
    },
    [SEMI_STANDARD_MESSAGE]: {
      value:
        get(semiStandardMessagePricingCall(pricing), 'base'),
      currency
    },
    [CUSTOM_MESSAGE]: {
      value:
        get(customMessagePricingCall(pricing), 'base'),
      currency
    }
  }
}

const rangePricing = ($pricing) => ({
  base: $pricing.range[0].price,
  extra: $pricing.range[1].price,
})

export const standardMessagePricingCall = pricing => {
  const $pricing = getStandardMessagePricing(pricing.messages)

  return {
    ...rangePricing($pricing),
    currency: get(pricing, 'currency.code')
  }
}

export const semiStandardMessagePricingCall = pricing => {
  const $pricing = getSemiStandardMessagePricing(pricing.messages)

  return {
    ...rangePricing($pricing),
    currency: get(pricing, 'currency.code')
  }
}

export const customMessagePricingCall = pricing => {
  const $pricing = getCustomMessagePricing(pricing.messages)

  return {
    base: get($pricing, 'range[0].characters[0].price'),
    characters: get($pricing, 'range[0].characters[0].max'),
    currency: get(pricing, 'currency.code')
  }
}
