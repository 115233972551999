import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import HelpIcon from '@material-ui/icons/Help'

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip)

export function HelpTooltip({ title = '', children }) {
  return (
    <HtmlTooltip
      placement="right"
      arrow
      title={
        <>
          {title && <Typography color="inherit">{title}</Typography>}
          {children}
        </>
      }
    >
      <IconButton size="small">
        <HelpIcon />
      </IconButton>
    </HtmlTooltip>
  )
}
