import { React } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { PageLoader } from '@slc/ui'

import { useAppApi } from '@slc/fetch'

import { Trans, defineMessage } from '@lingui/macro'
import { FetchError } from './FetchError'

const useStyles = makeStyles((theme) => ({
  empty: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(6),
    textAlign: 'center',
  },
}))

const defaultEmptyPlaceholderMessage = defineMessage({
  id: 'ui.content.empty.placeholder'
})

function EmptyPlaceholder({ className, message = defaultEmptyPlaceholderMessage }) {
  return (
    <div className={className}>
      <Trans id={message.id} />
    </div>
  )
}

function Content({ data, Renderer, classes, emptyMessage }) {
  if (!data) {
    return <EmptyPlaceholder className={classes.empty} message={emptyMessage} />
  }

  return <Renderer data={data} />
}

export const FetchableContent = ({
  useApi,
  fetchInfos,
  Renderer,
  Loader = null,
  emptyMessage
}) => {
  const classes = useStyles()

  const { data, error, statusCode, hasFetched, failed } = (useApi || useAppApi)(
    fetchInfos,
  )

  console.log({ data, error, statusCode, hasFetched, failed })
  let content = null
  let loader = null

  if (hasFetched) {
    content = failed
      ? (<FetchError statusCode={statusCode} error={error} />)
      : (<Content data={data} Renderer={Renderer} classes={classes} emptyMessage={emptyMessage} />)
  } else {
    loader = Loader ? <Loader /> : <PageLoader />
  }

  return !hasFetched ? loader : content
}
