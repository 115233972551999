import _defineProperty from "/vercel/path0/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import zipObject from 'lodash/zipObject';
import { links as $app } from './app';
import { links as $auth } from './auth';
import { links as $customer } from './customer';
import { links as $dashboard } from './dashboard';

var exposeLinks = function exposeLinks(name, $links) {
  var $$links = Object.keys($links);
  return _objectSpread({
    $name: name
  }, zipObject($$links, $$links.map(function (link) {
    return _objectSpread({
      name: name
    }, $links[link]);
  })));
};

export var app = exposeLinks('app', $app);
export var auth = exposeLinks('auth', $auth);
export var customer = exposeLinks('customer', $customer);
export var dashboard = exposeLinks('dashboard', $dashboard);
export var links = {
  app: app,
  auth: auth,
  customer: customer,
  dashboard: dashboard
};