import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import MuiStepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepButton from '@material-ui/core/StepButton'

import useMediaQuery from '@material-ui/core/useMediaQuery'

import { Trans } from '@lingui/macro'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  stepper: {
    background: 'transparent',
  },
}))

export function WizardStepper({
  steps,
  activeStep,
  setActiveStep,
  isLastStep,
}) {
  const classes = useStyles()
  const isMobileDevice = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  return (
    <div className={classes.root}>
      <MuiStepper
        alternativeLabel
        activeStep={activeStep}
        className={classes.stepper}
      >
        {steps.map(({ title }, index) => {
          const props = {}
          if (!isLastStep) {
            props.onClick = () => setActiveStep(index)
          }
          return (
            <Step key={`step${index}`}>
              <StepButton {...props}>
                {isMobileDevice ? '' : <Trans id={title.id} />}
              </StepButton>
            </Step>
          )
        })}
      </MuiStepper>
    </div>
  )
}
