import { $get, $post, appUrl, apiUrl } from '@slc/fetch'

export const createOrder = async (data, options) => {
  return $post(apiUrl('/order/create/funnel'), data, options)
}

export const createCreditOrder = async (data, options) => {
  return $post(apiUrl('/order/create/credit'), data, options)
}

export const fetchPaymentIntent = async (data, options) => {
  const { oid, ...payload } = data

  return $get(apiUrl('/order/:oid/payment/intent/fetch', { oid }), payload, options)
}

export const getPaymentIntent = async (data, options) => {
  const { oid } = data

  return $get(apiUrl('/order/:oid/payment/intent', { oid }), options)
}

export const confirmPaymentIntent = async (data, options) => {
  const { oid, ...payload } = data

  return $post(apiUrl('/order/:oid/payment/confirm', { oid }), payload, options)
}

export const paymentInstructions = async (data, options) => {
  const { oid, paymentType } = data

  if (!paymentType) {
    throw new Error('Missing `paymentType` parameters.')
  }

  return $get(appUrl(`/order/:oid/instructions/${paymentType}`, { oid }), options)
}

export const changePaymentType = async (data, options) => {
  const { oid, paymentType } = data

  if (!paymentType) {
    throw new Error('Missing `paymentType` parameters.')
  }

  const payload = { paymentType }

  return $post(apiUrl('/order/:oid/payment/change', { oid }), payload, options)
}

export const getOrderInfos = async (data, options) => {
  const { oid } = data

  return $get(appUrl('/order/:oid/infos', { oid }), options)
}

export const payByCredit = async (data, options) => {
  const { oid } = data

  return $post(apiUrl('/order/:oid/payment/payByCredit', { oid }), null, options)
}

export const orderDocuments = async (data, options) => {
  const { oid } = data

  return $get(appUrl('/order/:oid/documents', { oid }), options)
}
