export const SUBTOTAL_ORDER_LINE = 'subtotal'
export const DISCOUNT_ORDER_LINE = 'discount'
export const TAXES_ORDER_LINE = 'taxes'
export const TOTAL_ORDER_LINE = 'total'

export const ORDER_LINES = [
  SUBTOTAL_ORDER_LINE,
  DISCOUNT_ORDER_LINE,
  TAXES_ORDER_LINE,
  TOTAL_ORDER_LINE
]

