export const VISITOR_CONTACT_PROFILE = 'visitor'
export const CUSTOMER_CONTACT_PROFILE = 'customer'
export const RESELLER_CONTACT_PROFILE = 'reseller'
export const VOICE_CONTACT_PROFILE = 'voice'


export const CONTACT_PROFILES = [
  VISITOR_CONTACT_PROFILE,
  CUSTOMER_CONTACT_PROFILE,
  RESELLER_CONTACT_PROFILE,
  VOICE_CONTACT_PROFILE
]

export const ORDER_CONTACT_SUBJECT = 'order'
export const STUDIO_CONTACT_SUBJECT = 'studio'
export const ISSUE_CONTACT_SUBJECT = 'issue'
export const QUOTE_CONTACT_SUBJECT = 'quote'
export const QUESTION_CONTACT_SUBJECT = 'question'
export const OTHER_CONTACT_SUBJECT = 'other'

export const CONTACT_SUBJECTS = [
  ORDER_CONTACT_SUBJECT,
  STUDIO_CONTACT_SUBJECT,
  ISSUE_CONTACT_SUBJECT,
  QUOTE_CONTACT_SUBJECT,
  QUESTION_CONTACT_SUBJECT,
  OTHER_CONTACT_SUBJECT
]