export const MESSAGE_ORDER_ITEM_TYPE = 'message'
export const CART_OPTION_ORDER_ITEM_TYPE = 'cartOptions'
export const OPTION_ORDER_ITEM_TYPE = 'options'
export const FORMAT_ORDER_ITEM_TYPE = 'format'
export const SUPPORT_ORDER_ITEM_TYPE = 'support'
export const DISCOUNT_ORDER_ITEM_TYPE = 'discount'

export const ORDER_ITEM_TYPES = [
  MESSAGE_ORDER_ITEM_TYPE,
  CART_OPTION_ORDER_ITEM_TYPE,
  OPTION_ORDER_ITEM_TYPE,
  FORMAT_ORDER_ITEM_TYPE,
  SUPPORT_ORDER_ITEM_TYPE,
  DISCOUNT_ORDER_ITEM_TYPE
]

export const BASE_MESSAGE_ORDER_ITEM_TYPE = 'base'
export const EXTRA_MESSAGE_ORDER_ITEM_TYPE = 'extra'
export const TRANSLATION_MESSAGE_ORDER_ITEM_TYPE = 'translation'
export const OPTION_MESSAGE_ORDER_ITEM_TYPE = 'messageOption'

export const MESSAGE_ORDER_ITEM_TYPES = [
  BASE_MESSAGE_ORDER_ITEM_TYPE,
  EXTRA_MESSAGE_ORDER_ITEM_TYPE,
  TRANSLATION_MESSAGE_ORDER_ITEM_TYPE,
  OPTION_MESSAGE_ORDER_ITEM_TYPE
]
