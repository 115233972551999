import { Trans } from '@lingui/macro'
import {
  CARD_PAYMENT,
  TRANSFER_PAYMENT,
  CHECK_PAYMENT,
  CREDIT_PAYMENT,
} from '@slc/constants'

export const data = [
  {
    label: <Trans id="ui.cart.payment.cart" />,
    value: CARD_PAYMENT,
  },
  {
    label: <Trans id="ui.cart.payment.transfer" />,
    value: TRANSFER_PAYMENT,
  },
  {
    label: <Trans id="ui.cart.payment.check" />,
    value: CHECK_PAYMENT,
  },
  {
    label: <Trans id="ui.cart.payment.credit" />,
    value: CREDIT_PAYMENT,
  },
]
