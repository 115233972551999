import { i18n } from "@lingui/core";
export var links = {
  home: {
    href: '/',
    title:
    /*i18n*/
    {
      id: 'links.app.home'
    }
  },
  // shop
  order: {
    href: '/order',
    title:
    /*i18n*/
    {
      id: 'links.app.order'
    }
  },
  orderMusic: {
    href: '/order/music',
    title:
    /*i18n*/
    {
      id: 'links.app.orderMusic'
    }
  },
  orderVoice: {
    href: '/order/voice',
    title:
    /*i18n*/
    {
      id: 'links.app.orderVoice'
    }
  },
  orderMessage: {
    href: '/order/message',
    title:
    /*i18n*/
    {
      id: 'links.app.orderMessage'
    }
  },
  cart: {
    href: '/order/cart',
    title:
    /*i18n*/
    {
      id: 'links.app.cart'
    }
  },
  checkout: {
    href: '/order/checkout',
    title:
    /*i18n*/
    {
      id: 'links.app.checkout'
    }
  },
  orderPaid: {
    href: '/order/:oid/paid',
    title:
    /*i18n*/
    {
      id: 'links.app.orderPaid'
    }
  },
  waitingPayment: {
    href: '/order/:oid/payment/waiting',
    title:
    /*i18n*/
    {
      id: 'links.app.waitingPayment'
    }
  },
  creditPayment: {
    href: '/order/:oid/payment/credit',
    title:
    /*i18n*/
    {
      id: 'links.app.creditsPayment'
    }
  },
  creditPaymentComplete: {
    href: '/order/:oid/payment/credit/success' // title: defineMessage({
    //   id: 'links.app.creditPaymentComplete'
    // })

  },
  creditPaymentFailure: {
    href: '/order/:oid/payment/credit/failure' // title: defineMessage({
    //   id: 'links.app.creditPaymentFailure'
    // })

  },
  cartPayment: {
    href: '/order/:oid/payment/card',
    title:
    /*i18n*/
    {
      id: 'links.app.cartPayment'
    }
  },
  cartPaymentComplete: {
    href: '/order/:oid/payment/card/success' // title: defineMessage({
    //   id: 'links.app.cartPaymentComplete'
    // })

  },
  cartPaymentFailure: {
    href: '/order/:oid/payment/card/failure' // title: defineMessage({
    //   id: 'links.app.cartPaymentFailure'
    // })

  },
  // static
  about: {
    href: '/about',
    title:
    /*i18n*/
    {
      id: 'links.app.about'
    }
  },
  contact: {
    href: '/contact',
    title:
    /*i18n*/
    {
      id: 'links.app.contact'
    }
  },
  pricing: {
    href: '/pricing',
    title:
    /*i18n*/
    {
      id: 'links.app.pricing'
    }
  },
  // auths
  auth: {
    href: '/auth',
    title:
    /*i18n*/
    {
      id: 'links.app.auth'
    }
  },
  signup: {
    href: '/auth/signup',
    title:
    /*i18n*/
    {
      id: 'links.app.signup'
    }
  },
  signin: {
    href: '/api/auth/login',
    title:
    /*i18n*/
    {
      id: 'links.app.signout'
    }
  },
  signout: {
    href: '/api/auth/logout',
    title:
    /*i18n*/
    {
      id: 'links.app.signout'
    }
  },
  // legals
  terms: {
    href: '/legals/terms-of-use',
    title:
    /*i18n*/
    {
      id: 'links.app.terms'
    }
  },
  cookies: {
    href: '/legals/cookie-policy',
    title:
    /*i18n*/
    {
      id: 'links.app.cookies'
    }
  },
  privacy: {
    href: '/legals/privacy-policy',
    title:
    /*i18n*/
    {
      id: 'links.app.privacy'
    }
  },
  notice: {
    href: '/legals/notice',
    title:
    /*i18n*/
    {
      id: 'links.app.notice'
    }
  },
  support: {
    href: '/support',
    title:
    /*i18n*/
    {
      id: 'links.app.support'
    }
  },
  // reseller
  affiliate: {
    href: '/affiliates',
    title:
    /*i18n*/
    {
      id: 'links.app.affiliate'
    }
  }
};