import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  CardNumberElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js'

import Paper from '@material-ui/core/Paper'
import Divider from '@material-ui/core/Divider'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'

import { Form, Input } from '@slc/ui'

import { Trans } from '@lingui/macro'
import { CreditCardDetail } from './CreditCardDetail'
import { useCard } from './CardContext'

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(4),
    maxWidth: 480,
    borderRadius: theme.spacing(1.5),
    border: `thin solid ${theme.palette.divider}`,
  },
  divider: {
    marginBlock: theme.spacing(2),
  },
  errorMessage: {
    display: 'flex',
    flexDirection: 'horizontal',
    alignItems: 'center',
  },
  errorIcon: {
    paddingRight: theme.spacing(1),
  },
}))

export function CardPaymentForm({
  getPaymentIntent,
  onOrderCompleted,
  onOrderFailure,
}) {
  const classes = useStyles()
  const stripe = useStripe()
  const elements = useElements()
  const [intent, setIntent] = useState(null)
  const [complete, setComplete] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [values, dispatch] = useCard()

  useEffect(() => {
    const paymentIntent = async () => {
      const response = await getPaymentIntent()

      if (response.statusCode === 500) {
        return setErrorMessage(response.message)
      }

      setIntent(response)
    }

    paymentIntent()
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!stripe || !elements) {
      return
    }

    setErrorMessage('')

    console.log(e.currentTarget.reportValidity())

    if (!e.currentTarget.reportValidity()) {
      // return setPayment({ status: 'processing' })
    }

    try {
      const { error } = await stripe.confirmCardPayment(intent.secret, {
        payment_method: {
          card: elements.getElement(CardNumberElement),
          billing_details: {
            name: values.cardHolder.value,
          },
        },
      })

      if (error) {
        setErrorMessage(error.message)
      } else {
        setErrorMessage('')
        setComplete(true)

        onOrderCompleted()
      }
    } catch (e) {
      console.log(e)
      onOrderFailure()
    }
  }

  return (
    <>
      <Paper className={classes.card}>
        <Form
          onSubmit={handleSubmit}
          enabled={!!stripe && values.filled && !complete}
        >
          <div className="FormRow">
            <Input
              name="cardholder"
              label={<Trans id="ui.checkout.cart.cardholder" />}
              value={values.cardHolder.value}
              onChange={(cardHolder) => {
                dispatch({
                  type: 'cardHolder',
                  payload: {
                    value: cardHolder,
                  },
                })
              }}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>

          <div className="FormRow">
            <CreditCardDetail />
          </div>
        </Form>
        <Divider className={classes.divider} />
      </Paper>

      {errorMessage && (
        <div className={classes.errorMessage}>
          <ErrorOutlineIcon className={classes.errorIcon} />
          <p>{errorMessage}</p>
        </div>
      )}
    </>
  )
}
