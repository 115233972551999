const util = require('util')

export const logger = {
  debug: (...args) => {
    console.log.apply(console, ['[debug]'].concat(util.format(...args)))
  },

  info: (...args) => {
    console.log.apply(console, ['[info]'].concat(util.format(...args)))
  },

  warn: (...args) => {
    console.log.apply(console, ['[warn]'].concat(util.format(...args)))
  },

  error: (...args) => {
    console.log.apply(console, ['[error]'].concat(util.format(...args)))
  },

  fatal: (...args) => {
    console.log.apply(console, ['[fatal]'].concat(util.format(...args)))
  }
}
