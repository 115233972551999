import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import { WizardStepper } from './Stepper'

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 960,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}))

export function Wizard({ useWizard, children }) {
  const classes = useStyles()
  const { steps, activeStep, setActiveStep, isLastStep } = useWizard()

  return (
    <Grid container spacing={3} className={classes.root}>
      <Grid item xs={12}>
        <WizardStepper
          steps={steps}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          isLastStep={isLastStep}
          classes={{
            root: {
              padding: 0,
            },
          }}
        />
      </Grid>
      <Grid item xs={12}>
        {children}
      </Grid>
    </Grid>
  )
}
