import { React, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Pagination from '@material-ui/lab/Pagination'

import { PageLoader } from '@slc/ui'
import { insertQueryIntoUrl } from '@slc/utils'
import { useCustomerApi } from '@slc/fetch'

import { Trans, defineMessage } from '@lingui/macro'
import { FetchError } from './FetchError'

const LIMIT = 10

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  pagination: {
    textAlign: 'center',
  },
  empty: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(6),
    textAlign: 'center',
  },
}))

const defaultEmptyPlaceholderMessage = defineMessage({
  id: 'ui.list.empty.placeholder'
})

export function EmptyPlaceholder ({ className, message = defaultEmptyPlaceholderMessage }) {
  return (
    <div className={className}>
      <Trans id={message.id} />
    </div>
  )
}

export function ListContent({
  results = [],
  render,
  total,
  skip,
  limit,
  page,
  setPage,
  emptyMessage,
}) {
  const classes = useStyles()
  const pageCount = limit > 0 ? Math.round(total / limit) : 1
  const currentPage = limit > 0 ? Math.round(skip / limit) : 0
  const hasPages = total > limit

  const handleChange = (event, value) => {
    setPage(value)
  }

  if (!results.length) {
    return <EmptyPlaceholder className={classes.empty} message={emptyMessage}/>
  }

  return (
    <div className={classes.root}>
      {render({ results })}

      {hasPages && (
        <div className={classes.pagination}>
          <Pagination
            color="primary"
            shape="rounded"
            count={pageCount}
            defaultPage={currentPage || page}
            onChange={handleChange}
          />
        </div>
      )}
    </div>
  )
}

export function PaginatedContent({
  fetchInfos,
  toolbar = null,
  filters,
  render,
  emptyMessage,
}) {
  const classes = useStyles()

  const [page, setPage] = useState(1)

  console.debug('PaginatedContent -> switch to use useCustomerApi -- CORS temporary fix')
  // const { data, error } = useAppApi(
  const { data, error } = useCustomerApi(
    insertQueryIntoUrl(
      `${fetchInfos}?limit=${LIMIT}&skip=${(page - 1) * LIMIT}`,
      filters
    )
  )

  const hasFetched = data !== undefined || error !== undefined

  let content = null

  if (hasFetched) {
    content = error
      ? (<FetchError error={error} />)
      : (<ListContent {...data} page={page} setPage={setPage} render={render} emptyMessage={emptyMessage} />)
  }

  return (
    <div className={classes.root}>
      {toolbar}
      {!hasFetched ? <PageLoader /> : content}
    </div>
  )
}
