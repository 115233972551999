const find = require('lodash/find')
const findIndex = require('lodash/findIndex')
const get = require('lodash/get')
const camelCase = require('lodash/camelCase')
const filter = require('lodash/filter')
const matchesProperty = require('lodash/matchesProperty')
const last = require('lodash/last')
const constant = require('lodash/constant')
const has = require('lodash/has')
const difference = require('lodash/difference')
const isEmpty = require('lodash/isEmpty')
const property = require('lodash/property')
const tail = require('lodash/tail')
const { v4: uuid } = require('uuid')

const {
  STANDARD_MESSAGE,
  SEMI_STANDARD_MESSAGE,
  CUSTOM_MESSAGE
} = require('@slc/constants')

function rangePricing (message, langs = [], pricing, details = false) {
  const len = langs.length
  const basePrice = get(find(pricing.range, { max: 1 }), 'price')
  const { lid } = langs[0]

  if (len === 1) {
    return !details
      ? basePrice
      : [
          {
            lid,
            total: basePrice,
            pid: uuid(),
            base: basePrice
          }
        ]
  }

  const { price } = find(pricing.range, { min: 2 })

  return !details
    ? basePrice + (len - 1) * price
    : [
        {
          lid,
          total: basePrice,
          pid: uuid(),
          base: basePrice
        }
      ].concat(
        tail(langs).map(({ lid }) => ({
          lid,
          total: price,
          pid: uuid(),
          base: price
        }))
      )
}

function textLength (text = '') {
  return camelCase(text).length
}

function blockLength (block = {}) {
  return textLength(get(block, 'text', ''))
}

function messageLength ({ blocks = [], origin = {} }, lang = null) {
  const predicate =
    lang && has(lang, 'lid')
      ? matchesProperty('lang.lid', get(lang, 'lid'))
      : constant(true)

  const txtLen = filter(blocks, predicate)
    .map(block => blockLength(block))
    .reduce((acc, value) => acc + value, 0)

  return txtLen > 0 ? txtLen : blockLength(origin)
}

function getExtraRangeCount (msgLen, extraRange) {
  const offset = msgLen - extraRange.min

  if (offset < 0) {
    return 0
  }

  if (offset === 0) {
    return 1
  }

  return Math.ceil((offset + 1) / extraRange.size)
}

function getExtraRange (msgLen, pricingRange) {
  const extraRange = pricingRange.extra

  if (!extraRange) {
    return { price: 0, max: 0 }
  }

  const rangePrice = get(extraRange, 'price', 0)
  const rangeSize = get(extraRange, 'size', 0)

  const extraRangeCount =
    rangeSize > 0 ? getExtraRangeCount(msgLen, extraRange) : 0

  return {
    price: extraRangeCount * rangePrice,
    max: pricingRange.extra.min + extraRangeCount * rangeSize
  }
}

function messageCharacterPricing (msgLen, pricingRange, details = false) {
  const characterRange = get(pricingRange, 'characters', {})
  const baseRange = characterRange[0]

  if (!msgLen) {
    return !details
      ? 0
      : {
          total: 0,
          pid: uuid(),
          base: 0,
          range: {
            base: baseRange.max,
            extra: 0
          }
        }
  }

  const rangeIndex = findIndex(
    characterRange,
    ({ min, max }) => min <= msgLen && msgLen < max
  )

  if (rangeIndex > -1) {
    const { price, max } = characterRange[rangeIndex]

    if (price) {
      return !details
        ? price
        : {
            total: price,
            pid: uuid(),
            base: baseRange.price,
            extra: price - baseRange.price,
            range: {
              base: baseRange.max,
              extra: price > baseRange.price ? max : 0
            }
          }
    }
  }

  const lastBaseRange = last(characterRange)
  const extraRange = getExtraRange(msgLen, pricingRange)

  return !details
    ? get(lastBaseRange, 'price', 0) + get(extraRange, 'price', 0)
    : {
        total: lastBaseRange.price + extraRange.price,
        pid: uuid(),
        base: baseRange.price,
        extra: lastBaseRange.price + extraRange.price - baseRange.price,
        range: {
          base: baseRange.max,
          extra: extraRange.max
        }
      }
}

function messageTranslationPricing (msgLen, pricing) {
  return messageCharacterPricing(msgLen, get(pricing, 'translation'))
}

function characterPricing (message, langs, pricing, details = false) {
  const { blocks, translation = [], origin } = message.selection

  if (isEmpty(translation)) {
    return !details
      ? langs
          .map((lang, index) =>
            messageCharacterPricing(
              messageLength({ blocks, origin }, lang),
              pricing.range[index > 0 ? 1 : 0]
            )
          )
          .reduce((acc, value) => acc + value, 0)
      : langs.map((lang, index) => {
        const chars = messageLength({ blocks, origin }, lang)

        return {
          lid: lang.lid,
          chars,
          ...messageCharacterPricing(
            chars,
            pricing.range[index > 0 ? 1 : 0],
            details
          )
        }
      })
  }

  // search first language not translated to be the lang 1
  const lids = langs.map(property('lid'))
  const langsWithBlocks = difference(lids, translation)
  const hasOrigin = origin && origin.lang !== undefined

  const langsWithBlocksPrices = langsWithBlocks.map(lid => {
    const chars = messageLength({ blocks, origin }, lid)
    const index = langs.findIndex(lang => lang.lid === lid)

    return !details
      ? messageCharacterPricing(chars, pricing.range[index > 0 ? 1 : 0])
      : {
          lid,
          chars,
          ...messageCharacterPricing(
            chars,
            pricing.range[index > 0 ? 1 : 0],
            true
          )
        }
  })

  // retrieve length of first non translated message
  const chars = messageLength({ blocks, origin }, { lid: langsWithBlocks[0] })
  const originChars = hasOrigin && origin.text ? blockLength(origin) : 0

  const translatedPrices = translation.map(lid => {
    const isOrigin = hasOrigin && origin.lang === lid
    const $chars = isOrigin ? originChars : chars
    const index = langs.findIndex(lang => lang.lid === lid)

    // compute using message length and compute translation price
    if (!details) {
      return (
        messageCharacterPricing($chars, pricing.range[index > 0 ? 1 : 0]) +
        messageTranslationPricing($chars, pricing)
      )
    }

    const charPricing = messageCharacterPricing(
      $chars,
      pricing.range[index > 0 ? 1 : 0],
      details
    )
    const translationPricing = messageTranslationPricing($chars, pricing)

    return {
      lid,
      chars: $chars,
      ...charPricing,
      translation: translationPricing,
      total: charPricing.total + translationPricing
    }
  })

  if (!details) {
    return langsWithBlocksPrices
      .concat(translatedPrices)
      .reduce((acc, value) => acc + value, 0)
  }

  // build array using langs order
  const result = []

  langs.forEach(({ lid }) => {
    result.push(
      langsWithBlocksPrices.find(lang => lang.lid === lid) ||
        translatedPrices.find(lang => lang.lid === lid)
    )
  })

  return result
}

function computeMessagePrice (message, langs, pricing, details = false) {
  const messagePricing = get(
    find(pricing.messages, { name: message.category }),
    'pricing'
  )

  switch (message.category) {
    case STANDARD_MESSAGE:
    case SEMI_STANDARD_MESSAGE: {
      return rangePricing(
        message,
        langs,
        messagePricing,
        details
      )
    }

    case CUSTOM_MESSAGE: {
      return characterPricing(
        message,
        langs,
        messagePricing,
        details
      )
    }

    default:
      throw new Error(`Invalid message category: ${message.category}`)
  }
}

// TODO: fix messageLength as there might have some limits
function rangeOptionPrice (message, option) {
  const price = get(option, 'price', 0)
  const size = get(option, 'size', 0)

  if (message.category !== STANDARD_MESSAGE) {
    return price
  }

  const msgLen = messageLength(message)

  return size > 1 ? Math.ceil(msgLen / size) * price : price
}

function computeMessageOptionsPrice (message, pricing, details = false) {
  const options = get(message, 'options')

  if (!options || !pricing) {
    return !details ? 0 : { total: 0 }
  }

  const pricingOptions = get(pricing, 'options.message')

  if (isEmpty(pricingOptions)) {
    return !details ? 0 : { total: 0 }
  }

  let total = 0

  const $options = options
    .filter(({ enabled = true }) => enabled)
    .map(option => {
      const $option = find(pricingOptions, { name: option.name })
      const value = $option ? rangeOptionPrice(message, $option) : 0

      total += value

      return {
        ...option,
        value
      }
    })

  return !details
    ? total
    : {
        total,
        options: $options
      }
}

module.exports = {
  textLength,
  blockLength,
  messageLength,
  getExtraRangeCount,
  getExtraRange,
  computeMessagePrice,
  rangePricing,
  messageCharacterPricing,
  messageTranslationPricing,
  characterPricing,
  computeMessageOptionsPrice,
  rangeOptionPrice
}
