import React, { forwardRef, useState, useEffect } from 'react'

import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import attempt from 'lodash/attempt'
import isNull from 'lodash/isNull'

export function FormCheckbox(
  {
    name,
    label = null,
    checked = false,
    required = false,
    error = false,
    onChange,
    value,
    disabled = false
  },
  ref,
) {
  const [state, setState] = useState(false)

  useEffect(() => {
    setState(checked)
  }, [checked])

  const handleChange = (event) => {
    const { checked } = event.target

    setState(checked)
    attempt(onChange, event)
  }

  const hasError = !!error
  const checkbox = (
    <Checkbox
      ref={ref}
      checked={state}
      value={value}
      onChange={handleChange}
      color="primary"
      disabled={disabled}
    />
  )

  return isNull(label)
    ? checkbox
    : (
      <FormControl
        component="fieldset"
        required={required}
        error={hasError}
        fullWidth
      >
        <FormControlLabel key={name} label={label} control={checkbox} />
        {!hasError ? null : <FormHelperText>{error}</FormHelperText>}
      </FormControl>
      )
}

export default forwardRef(FormCheckbox)
