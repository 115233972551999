import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import { t } from '@lingui/macro'
import { Button } from '../buttons/Button'
import { Loader } from '../Loader/Loader'

const useStyles = makeStyles((theme) => ({
  footer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginLeft: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  loader: {
    marginRight: theme.spacing(1),
  },
}))

const defaultLabels = {
  previous: t({
    id: 'ui.form.footer.step.previous.label',
    message: 'Précédent',
  }),
  next: t({
    id: 'ui.form.footer.step.next.label',
    message: 'Suivant',
  }),
  submit: t({
    id: 'ui.form.footer.step.submit.label',
    message: 'Valider',
  }),
}

export function FormFooter({
  enabled = true,
  displayCancel = false,
  onCancel,
  loading = false,
  labels = defaultLabels,
}) {
  const classes = useStyles()

  return (
    <Box className={classes.footer}>
      {!displayCancel ? null : (
        <Button onClick={onCancel} className={classes.button}>
          {labels.previous}
        </Button>
      )}
      <Button type="submit" className={classes.button} disabled={!enabled}>
        {loading && (
          <Loader size={20} thickness={2} className={classes.loader} />
        )}
        {labels.submit}
      </Button>
    </Box>
  )
}
