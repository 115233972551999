import toPairs from 'lodash/toPairs'
import pick from 'lodash/pick'
import isEmpty from 'lodash/isEmpty'

export function Address({ address }) {
  if (isEmpty(address)) {
    return null
  }

  const tokens = toPairs(
    pick(address, 'addr1', 'addr2', 'zip', 'city', 'country'),
  )

  return (
    <p>
      {tokens.map(([key, value]) => (
        <span key={key}>
          {value}
          <br />
        </span>
      ))}
    </p>
  )
}
