import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'

import { payByCredit, getCustomerBalance } from '@slc/api-generic'
import isEmpty from 'lodash/isEmpty'
import { Loader } from '../../..'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  errorMessage: {
    display: 'flex',
    flexDirection: 'horizontal',
    alignItems: 'center',
  },
  errorIcon: {
    paddingRight: theme.spacing(1),
  },
}))

export function OrderCreditsPayment({
  infos,
  onOrderCompleted,
  onOrderFailure,
}) {
  const classes = useStyles()
  const [errorMessage, setErrorMessage] = useState('')

  const { oid, value } = infos

  useEffect(() => {
    const consumeCredit = async () => {
      const { balance } = await getCustomerBalance()

      const creditsConsumed = value / 100

      if (balance < creditsConsumed) {
        return onOrderFailure()
      }

      const response = await payByCredit({ oid })

      if (response.statusCode === 500) {
        return setErrorMessage(response.message)
      }

      onOrderCompleted()
    }

    consumeCredit()
  }, [])

  if (!isEmpty(errorMessage)) {
    return (
      <div className={classes.errorMessage}>
        <ErrorOutlineIcon className={classes.errorIcon} />
        <p>{errorMessage}</p>
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <Loader />
    </div>
  )
}
