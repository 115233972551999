import React, { useRef, useImperativeHandle } from 'react'
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js'
import TextField from '@material-ui/core/TextField'

import { Trans } from '@lingui/macro'

function StripeInput({ component: Component, inputRef, ...options }) {
  const elementRef = useRef()

  useImperativeHandle(inputRef, () => ({
    focus: () => elementRef.current.focus,
  }))

  return (
    <Component
      onReady={(element) => (elementRef.current = element)}
      {...options}
    />
  )
}

export function StripeTextFieldNumber(props) {
  return (
    <TextField
      fullWidth
      name="number"
      label={<Trans id="ui.stripe.card.number" />}
      variant="outlined"
      required
      {...props}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        inputComponent: StripeInput,
        inputProps: {
          component: CardNumberElement,
        },
      }}
    />
  )
}

export function StripeTextFieldExpiry(props) {
  return (
    <TextField
      fullWidth
      name="expiration"
      label={<Trans id="ui.stripe.card.expiration" />}
      variant="outlined"
      required
      {...props}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        inputComponent: StripeInput,
        inputProps: {
          component: CardExpiryElement,
        },
      }}
    />
  )
}

export function StripeTextFieldCVC(props) {
  return (
    <TextField
      fullWidth
      name="cvc"
      label={<Trans id="ui.stripe.card.cvc" />}
      variant="outlined"
      required
      {...props}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        inputComponent: StripeInput,
        inputProps: {
          component: CardCvcElement,
        },
      }}
    />
  )
}
