import { CardPayment } from '@slc/ui'

import { fetchPaymentIntent, confirmPaymentIntent } from '@slc/api-generic'

export function OrderCardPayment({ cid, oid, onOrderCompleted, onOrderFailure }) {
  return (
    <CardPayment
      getPaymentIntent={() => fetchPaymentIntent({ cid, oid })}
      onOrderCompleted={async () => {
        await confirmPaymentIntent({ oid })

        onOrderCompleted()
      }}
      onOrderFailure={onOrderFailure}
    />
  )
}
