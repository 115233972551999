export const MP3_FORMAT = 'mp3'
export const WAV_44K_FORMAT = 'wav_44k'
export const WAV_8K_16B_FORMAT = 'wav_8k16bits'
export const WAV_8K_8B_FORMAT = 'wav_8k8bits'
export const WAV_8K_8B_A_LAW_FORMAT = 'wav_8k8bits_Alaw'

export const FILE_FORMATS = [
  MP3_FORMAT,
  WAV_44K_FORMAT,
  WAV_8K_16B_FORMAT,
  WAV_8K_8B_FORMAT,
  WAV_8K_8B_A_LAW_FORMAT
]
