import React from 'react'
import { App } from '@slc/appshell'
import { useStore } from '@slc/store'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { ErrorBoundary } from 'react-error-boundary'
import { theme, ErrorFallback } from '@slc/ui'
import { Analytics } from '@vercel/analytics/react'

function MyApp(props) {
  const { Component, pageProps } = props

  const store = useStore(pageProps.initialReduxState)

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHAv3_KEY}
        scriptProps={{
          async: true,
          defer: true,
          appendTo: 'body'
        }}
      >
        <App
          store={store}
          Component={Component}
          pageProps={pageProps}
          theme={theme}
        />
      </GoogleReCaptchaProvider>
      <Analytics />
    </ErrorBoundary>
  )
}

export default MyApp
