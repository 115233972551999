import React, { useState, useEffect } from 'react'
import { getCustomerBalance } from '@slc/api-generic'
import { Trans } from '@lingui/macro'

import { LinkButton } from '@slc/ui'

export function OrderCreditsPaymentSuccess({ infos, orderLink }) {
  const { order, subtotal } = infos
  const [balance, setBalance] = useState('')

  useEffect(() => {
    const getCurrentBalance = async () => {
      const { balance: $balance } = await getCustomerBalance()
      setBalance($balance)
    }

    getCurrentBalance()
  }, [])

  const creditsConsumed = subtotal.value / 100

  return (
    <>
      <p>
        <Trans
          id="ui.order.payment.credit.success.usage"
          values={{ order, creditsConsumed }}
        >
          {creditsConsumed} crédits ont été déduits de votre solde pour le
          paiement de la commande {order}.
        </Trans>
      </p>
      <p>
        <Trans
          id="ui.order.payment.credit.success.balance"
          values={{ balance }}
        >
          Votre nouveau solde est de {balance} crédits.
        </Trans>
      </p>

      <LinkButton href={orderLink}>
        <Trans id="ui.order.payment.credit.success.orderLink" />
      </LinkButton>
    </>
  )
}
