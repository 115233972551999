import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useRef,
  useCallback,
  useEffect
} from 'react'

const initialState = {}

const AudioContext = createContext(initialState)

export const useAudio = () => useContext(AudioContext)

export const AudioProvider = ({ children }) => {
  const [sound, setSound] = useState(null)
  const HowlConstructor = useRef(null)

  const handleLoad = function () {
    console.log('handleLoad', this.duration())
    // setDuration(this.duration() * 1000)
  }

  const updateSound = useCallback((url, events = {}) => {
    console.log('updateSound', url, sound, sound && sound.state())
    if (sound && sound.state() === 'unloaded') {
      sound.stop()
    }

    const $sound = new HowlConstructor.current({
      src: Array.isArray(url) ? url : [url],
      onload: handleLoad,
      // autoload: true
      onplayerror: function () {
        $sound.once('unlock', function () {
          $sound.play()
        })
      }
    })

    if (events.onstop) {
      $sound.once('stop', () => events.onstop())
    }

    $sound.once('end', () => {
      console.log('end')
      $sound.off()
      setSound(null)

      if (events.onend) {
        events.onend()
      }
    })

    $sound.play()

    setSound($sound)
  }, [])

  useEffect(() => {
    import('howler').then(mod => {
      HowlConstructor.current = mod.Howl
    }).catch(e => {
      console.log('Failed to load howler')
    })
  }, [])

  const play = useCallback(
    (url, events) => {
      if (!sound) {
        updateSound(url, events)
        return
      }

      console.log('playing url', url, sound._src)
      console.log('playing sound', sound.duration)
      if (url !== sound._src) {
        console.log('stop sound')
        sound.stop()
        updateSound(url, events)
        return
      }

      sound.play()
    },
    [sound, updateSound]
  )

  const stop = useCallback(
    (inform = true) => {
      if (!sound) {
        return
      }

      if (!inform) {
        sound.off(stop)
      }

      sound.stop()
    },
    [sound]
  )

  const value = useMemo(
    () => ({
      sound,
      updateSound,
      play,
      stop
    }),
    [sound, updateSound, play, stop]
  )

  return <AudioContext.Provider value={value}>{children}</AudioContext.Provider>
}
