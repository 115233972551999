import React from 'react'
import { Trans } from '@lingui/macro'

import { LinkButton } from '@slc/ui'

export function OrderPaidSuccess({ orderLink }) {
  return (
    <>
      <p>
        <Trans id="ui.order.paid.success" />
      </p>

      <LinkButton href={orderLink}>
        <Trans id="ui.order.paid.success.orderLink" />
      </LinkButton>
    </>
  )
}
