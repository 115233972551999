import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { LinkButton } from '@slc/ui'

import { Trans } from '@lingui/macro'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    marginRight: theme.spacing(2),
  },
}))

export function OrderCreditsPaymentFailure({ orderLink, topupLink }) {
  const classes = useStyles()

  return (
    <>
      <p>
        <Trans id="ui.order.payment.credit.error.description" />
      </p>

      <div className={classes.root}>
        <LinkButton href={orderLink} className={classes.button}>
          <Trans id="ui.order.payment.credit.error.order.page.linkbutton">
            Voir la commande
          </Trans>
        </LinkButton>
        <LinkButton href={topupLink} className={classes.button}>
          <Trans id="ui.order.payment.credit.error.topup.page.linkbutton">
            Créditer votre compte
          </Trans>
        </LinkButton>
      </div>
    </>
  )
}
