import React, { forwardRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import attempt from 'lodash/attempt'
import has from 'lodash/has'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBlock: theme.spacing(1),
  },
  horizontal: {
    flexDirection: 'row',
    '& > label': {
      marginRight: theme.spacing(1.5),
    },
  },
  vertical: {
    flexDirection: 'column',

    '& > label': {
      marginBottom: theme.spacing(1),
      lineHeight: 1.2,
    },
  },
  legend: {
    marginBottom: theme.spacing(1),
  },
  formControlLabel: {
    marginRight: 0,
  },
}))

function DefaultRenderer({
  name,
  defaultValue,
  disabled,
  classes,
  handleChange,
  radioClassName,
  choices,
}) {
  return (
    <RadioGroup
      aria-label={name}
      name={name}
      onChange={handleChange}
      className={radioClassName}
    >
      {choices.map((choice, i) => (
        <FormControlLabel
          key={`${name}-${i}`}
          value={choice.value}
          checked={choice.value === defaultValue}
          control={<Radio color="primary" disabled={disabled} />}
          disabled={has(choice, 'enabled') && !choice.enabled}
          label={choice.label}
          classes={{
            root: classes.formControlLabel,
          }}
        />
      ))}
    </RadioGroup>
  )
}

export function FormRadioButtons(
  {
    name,
    label,
    choices = [],
    controlled = false,
    defaultValue = undefined,
    value,
    required = false,
    error,
    onChange,
    disabled = false,
    orientation = 'vertical',
    renderer = null,
  },
  ref,
) {
  const classes = useStyles()

  const [state, setState] = useState({
    value: controlled ? value : defaultValue,
  })

  const handleChange = (event) => {
    const { value } = event.target
    setState({
      ...state,
      value,
    })
    attempt(onChange, event)
  }

  const hasError = !!error
  const radioClassName = classes[orientation] || classes.vertical

  const Renderer = renderer || DefaultRenderer

  return (
    <FormControl
      className={classes.root}
      component="fieldset"
      required={required}
      error={hasError}
      fullWidth
    >
      <FormLabel component="legend" className={classes.legend}>
        {label}
      </FormLabel>

      <Renderer
        name={name}
        defaultValue={defaultValue}
        choices={choices}
        disabled={disabled}
        handleChange={handleChange}
        radioClassName={radioClassName}
        classes={classes}
      />
      {!hasError ? null : <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}

export default forwardRef(FormRadioButtons)
