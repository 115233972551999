import isEmpty from 'lodash/isEmpty'

const paramPattern = /:([^/]+)/g

export const insertQueryIntoUrl = (href, query = {}) => {
  if (isEmpty(query)) {
    return href
  }

  const queryParams = []

  Object.keys(query).forEach(name => {
    queryParams.push(`${name}=${encodeURIComponent(query[name])}`)
  })

  const queryStr = queryParams.join('&')

  return href.indexOf('?') === -1 ? `${href}?${queryStr}` : `${href}&${queryStr}`
}

export const insertParamsIntoUrl = (href, params, query) => {
  if (isEmpty(params)) {
    return insertQueryIntoUrl(href, query)
  }

  const tokens = href.match(paramPattern)

  if (!tokens) {
    return insertQueryIntoUrl(href, query)
  }

  tokens.forEach(token => {
    const value = params[token.substring(1)]

    if (value) {
      href = href.replace(token, value)
    }
  })

  return insertQueryIntoUrl(href, query)
}

export const pathHrefToRoute = (href = '') => {
  const tokens = href.match(paramPattern)

  if (!tokens) {
    return href
  }

  tokens.forEach(token => {
    const name = token.substring(1)

    if (name) {
      href = href.replace(token, `[${name}]`)
    }
  })

  return href
}
