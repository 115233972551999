import { makeStyles } from '@material-ui/core/styles'
import WarningIcon from '@material-ui/icons/Warning'

import { H3 } from '@slc/ui'

import { Trans } from '@lingui/macro'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(6),
    textAlign: 'center',
  },
  icon: {},
  title: {},
  message: {},
}))

export function FetchError({ error, message }) {
  const classes = useStyles()

  console.log('FetchError', error)

  return (
    <div className={classes.root}>
      <div className={classes.icon}>
        <WarningIcon />
      </div>

      <H3 className={classes.title}>
        <Trans id="ui.fetch.error.title" />
      </H3>

      <p className={classes.message}>
        {message || <Trans id="ui.fetch.error.defaultMessage" />}
      </p>
    </div>
  )
}
