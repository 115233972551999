import { createSlice } from '@reduxjs/toolkit'
import pick from 'lodash/pick'

const initialState = {
  site: process.env.SITE,
  currency: {
    name: null,
    symbol: null,
    code: null
  },
  tax: {
    name: null,
    percent: null
  },
  lang: [],
  mobileProviders: []
}

const countrySlice = createSlice({
  name: 'country',
  initialState,
  reducers: {
    setInfos (state, action) {
      const options = pick.apply(null, [action.payload].concat(Object.keys(initialState)))

      let name

      for (name in options) {
        state[name] = options[name]
      }
    }
  }
})

const { actions, reducer } = countrySlice

export const { setInfos } = actions

export default reducer
