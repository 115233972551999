import React from 'react'

import NextLink from 'next/link'

export function Link({
  href,
  className,
  children,
  clientSide = true,
  ...others
}) {
  return clientSide ? (
    <NextLink href={href} passHref>
      <a className={className} {...others}>
        {children}
      </a>
    </NextLink>
  ) : (
    <a href={href} className={className} {...others}>
      {children}
    </a>
  )
}
