import { dayjs } from './date'
import 'dayjs-locale'

dayjs.locale(process.env.NEXT_PUBLIC_SITE)

export const formatDate = date => dayjs(date).format('DD/MM/YYYY')

export const formatFullDate = date => dayjs(date).format('DD MMMM YYYY')

export const formatISODate = date => dayjs(date).format('YYYY-MM-DD')
export const formatISODateTime = date => dayjs(date).format('YYYY-MM-DD HH:mm:ss.Z')
