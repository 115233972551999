import has from 'lodash/has'
import get from 'lodash/get'
import { validate } from '@slc/validation'

export const fieldError = (errors, name) => {
  const hasError = has(errors, name)

  return {
    error: hasError,
    helperText: hasError ? get(errors, `${name}.type`) : null
  }
}

export const formResolver = $schema => async values => {
  const { errors = {} } = await validate({ validation: $schema, data: values })

  return {
    errors,
    values
  }
}

export const formConfig = ({ defaultValues = {}, $schema }) => ({
  mode: 'onSubmit',
  reValidateMode: 'onChange',
  defaultValues,
  resolver: formResolver($schema)
})
