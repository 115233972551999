import values from 'lodash/values'

import { insertParamsIntoUrl } from '@slc/utils'

import { app, auth, customer, dashboard } from './links'

const links = {
  app: values(app),
  auth: values(auth),
  customer: values(customer),
  dashboard: values(dashboard)
}

const $getLinkHref = (path, params, collectionName, baseUrl = '') => {
  if (!baseUrl) {
    throw new Error('Missing base url')
  }

  if (!links[collectionName].includes(path)) {
    throw new Error(`Link ${path} does not exist for collection ${collectionName}.`)
  }

  return insertParamsIntoUrl(`${baseUrl}${path}`, params)
}

export const getAuthLinkHref = (name, params) =>
  $getLinkHref(name, params, 'auth', process.env.NEXT_PUBLIC_AUTH_URL)

export const getAppLinkHref = (name, params) =>
  $getLinkHref(name, params, 'app', process.env.NEXT_PUBLIC_APP_URL)

export const getCustomerLinkHref = (name, params) =>
  $getLinkHref(name, params, 'customer', process.env.NEXT_PUBLIC_CUSTOMER_URL)

export const getDashboardLinkHref = (name, params) =>
  $getLinkHref(name, params, 'dashboard', process.env.NEXT_PUBLIC_DASHBOARD_URL)
