import Ajv from 'ajv'
import addFormats from 'ajv-formats'

export const ajv = new Ajv({
  // schemaId: 'auto',
  allErrors: true,
  $data: true,
  async: true
})

ajv.addKeyword({
  keyword: 'equal',
  validate: (schema, data, parentSchema, ctx) => {
    if (parentSchema.type !== 'string') {
      return true
    }

    const other = ctx.rootData[schema]

    return other ? other === data : true
  }
})

addFormats(ajv)
