import _slicedToArray from "/vercel/path0/node_modules/next/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import _defineProperty from "/vercel/path0/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import get from 'lodash/get';
import isString from 'lodash/isString';
import { insertParamsIntoUrl } from '@slc/utils';
import { app, auth, customer, dashboard } from './links'; // --> copy and adapted from @slc/links

var $getLink = function $getLink(name) {
  var collection = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var baseUrl = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  var link = isString(name) ? get(collection, name) : name;

  if (!link) {
    throw new Error("Invalid link #[".concat(name, "] for collection ").concat(collection.$name, "."));
  }

  if (!baseUrl) {
    return link;
  }

  return _objectSpread(_objectSpread({}, link), {}, {
    href: "".concat(baseUrl).concat(link.href)
  });
};

var $getLinkHref = function $getLinkHref(name) {
  var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      params = _ref.params,
      query = _ref.query;

  var collection = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  var baseUrl = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';

  if (!baseUrl) {
    throw new Error('Missing base url');
  }

  if (!collection) {
    throw new Error('Missing collection');
  }

  var link = get(isString(name) ? $getLink(name, collection) : name, 'href');
  return insertParamsIntoUrl("".concat(baseUrl).concat(link), params, query);
};

export var getAuthLinkHref = function getAuthLinkHref(name, params, query) {
  return $getLinkHref(name, {
    params: params,
    query: query
  }, auth, process.env.NEXT_PUBLIC_AUTH_URL);
};
export var getAppLinkHref = function getAppLinkHref(name, params, query) {
  return $getLinkHref(name, {
    params: params,
    query: query
  }, app, process.env.NEXT_PUBLIC_APP_URL);
};
export var getCustomerLinkHref = function getCustomerLinkHref(name, params, query) {
  return $getLinkHref(name, {
    params: params,
    query: query
  }, customer, process.env.NEXT_PUBLIC_CUSTOMER_URL);
};
export var getDashboardLinkHref = function getDashboardLinkHref(name, params, query) {
  return $getLinkHref(name, {
    params: params,
    query: query
  }, dashboard, process.env.NEXT_PUBLIC_DASHBOARD_URL);
}; // <--

var pathPattern = /^https?:\/\/([^/]+)(.*)$/;
export var isLinkActive = function isLinkActive(href, currentHost) {
  var currentPath = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  var match = href.match(pathPattern);

  if (!match) {
    return false;
  }

  var _match = _slicedToArray(match, 3),
      host = _match[1],
      path = _match[2];

  var $path = path === '' ? '/' : path;
  return host === currentHost && $path === currentPath;
};