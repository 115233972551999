import React from 'react'
import Grid from '@material-ui/core/Grid'
import {
  StripeTextFieldNumber,
  StripeTextFieldExpiry,
  StripeTextFieldCVC,
} from './common'

import { useCard } from './CardContext'

export function CreditCardDetail() {
  const [values, dispatch] = useCard()

  const onChange = ({ elementType, complete, error }) => {
    dispatch({
      type: elementType,
      payload: { complete, error },
    })
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <StripeTextFieldNumber
          error={Boolean(values.cardNumber.error)}
          labelErrorMessage={values.cardNumber.error}
          onChange={onChange}
        />
      </Grid>
      <Grid item xs={6} sm={4} md={3}>
        <StripeTextFieldExpiry
          error={Boolean(values.cardExpiry.error)}
          labelErrorMessage={values.cardExpiry.error}
          onChange={onChange}
        />
      </Grid>
      <Grid item xs={6} sm={4} md={3}>
        <StripeTextFieldCVC
          error={Boolean(values.cardCvc.error)}
          labelErrorMessage={values.cardCvc.error}
          onChange={onChange}
        />
      </Grid>
    </Grid>
  )
}
