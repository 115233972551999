import { loadStripe } from '@stripe/stripe-js'

let stripePromise

export const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY, {
      locale: process.env.SITE
    })
  }
  return stripePromise
}

export const formatAmount = ({ value, currency, locale = 'fr-FR' }) => {
  const numberFormat = new Intl.NumberFormat([locale], {
    style: 'currency',
    currency: currency,
    currencyDisplay: 'symbol',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  })

  const parts = numberFormat.formatToParts(value)

  const integers = []
  let zeroDecimalCurrency = true

  for (const part of parts) {
    if (part.type === 'decimal') {
      zeroDecimalCurrency = false
    }
    if (part.type === 'integer') {
      integers.push(part.value)
    }
  }

  if (!zeroDecimalCurrency) {
    return Math.round(value * 100)
  }

  const numeric = integers.join('')

  return numeric === value ? value : Math.round(value * 100)
}
