export const links = {
  dashboard: '/',
  orders: '/order',
  order: '/order/:oid',
  orderPayment: '/order/:oid/payment',
  orderSelectPayment: '/order/:oid/payment/select',
  waitingPayment: '/order/:oid/payment/waiting',
  creditPayment: '/order/:oid/payment/credit',
  cartPayment: '/order/:oid/payment/card',
  cartPaymentComplete: '/order/:oid/payment/card/success',
  cartPaymentFailure: '/order/:oid/payment/card/failure',
  orderStudio: '/order/:oid/studio',
  orderCertificate: '/order/:oid/certificate',
  orderSupport: '/order/:oid/support',
  orderDetails: '/order/:oid/details',
  orderInvoice: '/order/:oid/invoice',
  downloadOrderInvoice: '/order/:oid/invoice/download',
  quotes: '/quote',
  quote: '/quote/:oid',
  invoices: '/invoices',
  infos: '/infos',
  topup: '/credit/topup',
  topupCheckout: '/credit/topup/checkout',
  statements: '/credit/statement',
  support: '/support',
  signout: '/api/auth/logout',
  signin: '/auth/signin'
}
