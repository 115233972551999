import { makeStyles } from '@material-ui/core/styles'

import { H2, LinkButton, OrderPaymentInstructions } from '@slc/ui'

import { formatPrice } from '@slc/utils'
import { Trans, defineMessage } from '@lingui/macro'

import get from 'lodash/get'

import { CHECK_PAYMENT, TRANSFER_PAYMENT } from '@slc/constants'

const messages = {
  [CHECK_PAYMENT]: {
    title: defineMessage({
      id: 'ui.order.payment.waiting.check.title',
    }),
    description: defineMessage({
      id: 'ui.order.payment.waiting.check.description',
    }),
    reference: defineMessage({
      id: 'ui.order.payment.waiting.check.reference',
    }),
  },
  [TRANSFER_PAYMENT]: {
    title: defineMessage({
      id: 'ui.order.payment.waiting.transfer.title',
    }),
    description: defineMessage({
      id: 'ui.order.payment.waiting.transfer.description',
    }),
    reference: defineMessage({
      id: 'ui.order.payment.waiting.transfer.reference',
    }),
  },
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  loader: {
    justifyContent: 'center',
  },
}))

export function OrderPaymentWaiting({
  infos,
  paymentType,
  instructions,
  orderLink,
}) {
  const classes = useStyles()
  const { order, total } = infos
  const price = formatPrice(total, { fromServer: true })

  return (
    <div className={classes.root}>
      <H2>
        <Trans id={get(messages, `[${paymentType}].title`)} />
      </H2>
      <p>
        <Trans
          id={get(messages, `[${paymentType}].description`)}
          values={{ price }}
        >
          Commande d'un montant de {price}.
        </Trans>
      </p>

      <p>
        <Trans
          id={get(messages, `[${paymentType}].reference`)}
          values={{ order }}
        >
          Indiquer la référence {order} comme référence au virement.
        </Trans>
      </p>

      <OrderPaymentInstructions
        payment={paymentType}
        instructions={instructions}
      />

      <LinkButton href={orderLink}>
        <Trans id="ui.order.complete.customer.page.button" />
      </LinkButton>
    </div>
  )
}
