import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { LinkButton } from '@slc/ui'

import { Trans } from '@lingui/macro'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    marginRight: theme.spacing(2),
  },
}))

export function OrderCardPaymentFailure({ orderLink }) {
  const classes = useStyles()

  return (
    <>
      <p>
        <Trans id="ui.order.payment.card.error.description" />
      </p>

      <LinkButton href={orderLink} className={classes.button}>
        <Trans id="ui.order.payment.card.error.order.page.linkbutton">
          Voir la commande
        </Trans>
      </LinkButton>
    </>
  )
}
