import trim from 'lodash/trim'
import isEmpty from 'lodash/isEmpty'
import isUndefined from 'lodash/isUndefined'

export const formatPriceValue = (value, digits = 0) =>
  (Math.round(100 * value) / 100).toFixed(digits)

/**
 *
 * @param {number} price.value
 * @param {string} price.currency
 * @param {number} options.digits (default: 0)
 * @param {boolean} options.fromServer price value is sent by server and with cents included without comma (default: false)
 * @returns {string}
 */
export const formatPrice = ({ value, currency = '' } = {}, { digits, fromServer = true } = {}) => {
  if (isNaN(value)) {
    return '-'
  }

  const priceValue = formatPriceValue(
    fromServer ? value / 100 : value,
    useDigits({ digits, fromServer })
  )

  return trim(`${priceValue} ${currency}`)
}

export const useDigits = ({ digits, fromServer = true } = {}) => {
  if (!isUndefined(digits)) {
    return digits
  }

  return fromServer ? 2 : 0
}

export const formatPriceWithCurrency = (value, currency) => {
  if (isNaN(value) || isEmpty(currency)) {
    throw new Error('Invalid price data')
  }

  return {
    value: parseInt(formatPriceValue(100 * value), 10),
    currency
  }
}
