import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import MuiTooltip from '@material-ui/core/Tooltip'

const TextTooltip = withStyles((theme) => ({
  tooltip: {
    // backgroundColor: '#f5f5f9',
    // color: 'rgba(0, 0, 0, 0.87)',
    // maxWidth: 220,
    // fontSize: theme.typography.pxToRem(12),
    // border: '1px solid #dadde9'
  },
}))(MuiTooltip)

export function Tooltip({ title = '', children }) {
  return (
    <TextTooltip placement="bottom" title={title}>
      {children}
    </TextTooltip>
  )
}
