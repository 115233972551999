import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import MuiTabs from '@material-ui/core/Tabs'

export const Tabs = withStyles((theme) => ({
  root: {
    borderRadius: theme.spacing(0.5),
    marginBottom: theme.spacing(2),
  },
  indicator: {
    backgroundColor: 'transparent',
  },
}))((props) => <MuiTabs {...props} />)
