const get = require('lodash/get')

const {
  computeMessagePrice,
  computeMessageOptionsPrice,
  messageLength,
  blockLength,
  textLength
} = require('./helper')

function cartPricing (cart, pricing, details = false) {
  if (!cart) {
    throw new Error('Missing cart')
  }

  if (!pricing) {
    throw new Error('Missing pricing')
  }

  const messages = cart.map(item =>
    messagePricing(item, pricing, get(item, 'langs', []), details)
  )

  return {
    total: messages.reduce((acc, { total }) => acc + total, 0),
    messages
  }
}

function messagePricing (messageInfos, pricing, langs = [], details = false) {
  if (!messageInfos) {
    throw new Error('Missing message informations')
  }

  if (!pricing) {
    throw new Error('Missing pricing')
  }

  const { mid, message } = messageInfos

  if (!details) {
    return {
      mid,
      total:
        computeMessagePrice(message, langs, pricing) +
        computeMessageOptionsPrice(message, pricing)
    }
  }

  const parts = computeMessagePrice(message, langs, pricing, details)
  const options = computeMessageOptionsPrice(message, pricing, details)

  return {
    mid,
    total: parts.reduce((acc, { total }) => acc + total, 0) + options.total,
    parts,
    options
  }
}

module.exports = {
  cartPricing,
  messagePricing,
  messageLength,
  blockLength,
  textLength
}
