import isString from 'lodash/isString'
import isObject from 'lodash/isObject'
import isArray from 'lodash/isArray'
import isDate from 'lodash/isDate'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'

const predicate = v => {
  if (isDate(v)) {
    return v
  }

  if (isString(v) || isObject(v) || isArray(v)) {
    return !isEmpty(v)
  }

  return !isNil(v)
}

export const nonEmpty = o => {
  const out = {}

  Object.keys(o)
    .filter(key => predicate(o[key]))
    .forEach(key => {
      out[key] = o[key]
    })

  return out
}

export const getNonEmpty = (obj, prop, defaultValue = undefined) => {
  const value = get(obj, prop, defaultValue)

  return isEmpty(value) ? defaultValue : value
}
