export const scrollToTop = () => {
  const anchor = document.querySelector('#back-to-top-anchor')

  if (anchor) {
    anchor.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    })
  }
}
