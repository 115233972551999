import { i18n } from "@lingui/core";
export var links = {
  // dashboard
  dashboard: {
    href: '',
    title:
    /*i18n*/
    {
      id: 'links.dashboard.dashboard'
    }
  }
};