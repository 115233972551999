export const DOWNLOAD_SUPPORT = 'download'
export const AUDIOCD_SUPPORT = 'audiocd'
export const CDROM_SUPPORT = 'cdrom'
export const TAPE_SUPPORT = 'tape'

export const FILE_SUPPORTS = [
  DOWNLOAD_SUPPORT,
  AUDIOCD_SUPPORT,
  CDROM_SUPPORT,
  TAPE_SUPPORT
]
