import {
  ACTIVE_DISCOUNT,
  AMOUNT_DISCOUNT,
  NEXT_ACTIVE_DISCOUNT,
  PERCENT_DISCOUNT,
} from '@slc/constants'

import { formatPrice } from '@slc/utils'

export const findAvailablePromoCode = ({ messages = [], pack = [] }) => {
  if (!pack || !pack.length) {
    return []
  }

  const len = messages.length
  const discounts = []

  const available = pack.filter(({ count }) => count <= len)

  if (available.length) {
    discounts.push({
      code: available[available.length - 1],
      type: ACTIVE_DISCOUNT,
    })
  }

  const next = pack.filter(({ count }) => count === len + 1)

  if (next.length) {
    discounts.push({
      code: next[0],
      type: NEXT_ACTIVE_DISCOUNT,
    })
  }

  return discounts
}

export const formatBonus = ({ value, unit } = {}) => {
  switch (unit) {
    case PERCENT_DISCOUNT: {
      return formatPrice(
        { value: -value, currency: '%' },
        { fromServer: false },
      )
    }

    case AMOUNT_DISCOUNT:
    default: {
      return ''
    }
  }
}

export const formatBonusValue = ({ value, unit } = {}, currency = '') => {
  switch (unit) {
    case PERCENT_DISCOUNT: {
      return formatPrice(
        { value: value, currency: '%' },
        { fromServer: false }
      )
    }

    case AMOUNT_DISCOUNT:
      return formatPrice(
        { value, currency },
        { fromServer: true }
      )
    default: {
      return ''
    }
  }
}
