import React from 'react'
import MuiButton from '@material-ui/core/Button'

export function Button({ children, ...others }) {
  return (
    <MuiButton disableElevation variant="contained" color="primary" {...others}>
      {children}
    </MuiButton>
  )
}

export default Button
