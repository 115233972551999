export const PREDECROCHE_MSG_TYPE = 'predecroche'
export const ATTENTE_MSG_TYPE = 'attente'
export const REPONDEUR_MSG_TYPE = 'repondeur'
export const GSM_MSG_TYPE = 'gsm'
export const VOEUX_MSG_TYPE = 'voeux'

export const MESSAGE_TYPES = [
  PREDECROCHE_MSG_TYPE,
  ATTENTE_MSG_TYPE,
  REPONDEUR_MSG_TYPE,
  GSM_MSG_TYPE,
  VOEUX_MSG_TYPE
]
