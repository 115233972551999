import { Checkbox } from '@slc/ui'
import { Trans } from '@lingui/macro'

export function OrderAcceptation({ value, onChange }) {
  return (
    <Checkbox
      name="cartAcceptation"
      label={<Trans id="ui.cart.acceptation.label" />}
      controlled={false}
      checked={value}
      onChange={(e) => onChange(e.currentTarget.checked)}
    />
  )
}
