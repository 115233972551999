import { Input } from '@slc/ui'
import { Trans } from '@lingui/macro'

export function OrderName({ value, onChange }) {
  return (
    <Input
      fullWidth
      name="cartName"
      label={<Trans id="ui.cart.name.label" />}
      defaultValue={value}
      value={value}
      onBlur={(event) => {
        onChange(event.currentTarget.value)
      }}
    />
  )
}
