import { insertParamsIntoUrl } from '@slc/utils'

export const url = (baseUrl, path) => {
  if (!baseUrl) {
    throw new Error('Missing baseUrl')
  }

  if (!path) {
    return baseUrl
  }

  return [baseUrl, path].join(path[0] === '/' ? '' : `/${path}`)
}

const prependApi = path => path.substring(0, 4) === '/api' ? path : url('/api', path)

const buildUrl = (baseUrl, path, params) => insertParamsIntoUrl(url(baseUrl, prependApi(path)), params)

export const apiUrl = (path, params = {}) => buildUrl(process.env.NEXT_PUBLIC_SDK_API_URL || process.env.NEXT_PUBLIC_API_URL, path, params)
export const appUrl = (path, params = {}) => buildUrl(process.env.NEXT_PUBLIC_APP_URL, path, params)
export const authUrl = (path, params = {}) => buildUrl(process.env.NEXT_PUBLIC_AUTH_URL, path, params)
export const customerUrl = (path, params = {}) => buildUrl(process.env.NEXT_PUBLIC_CUSTOMER_URL, path, params)
export const dashboardUrl = (path, params = {}) => buildUrl(process.env.NEXT_PUBLIC_DASHBOARD_URL, path, params)

export const auth0Url = path => url(`https://${process.env.AUTH0_DOMAIN}`, path)
export const auth0ApiUrl = path => url(`https://${process.env.AUTH0_DOMAIN}/api/v2`, path)
