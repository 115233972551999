import ReactCountryFlag from 'react-country-flag'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

export function Flag({ country, large = false }) {
  return (
    <ReactCountryFlag
      alt={country.name}
      countryCode={country.flag}
      key={country.code}
      svg
      style={{
        width: large ? '2em' : '1em',
      }}
    />
  )
}

export function LangFlag({ lang }) {
  const name = get(lang, 'name')
  const code = get(lang, 'code')
  if (isEmpty(name) || isEmpty(code)) {
    throw new Error('Invalid lang definition')
  }

  return (
    <Flag
      large
      country={{
        name,
        code,
        flag: code,
      }}
    />
  )
}
