import has from 'lodash/has'
import pick from 'lodash/pick'

export const parseApiResponseStatus = response =>
  has(response, 'status')
    ? Math.floor(response.status / 100)
    : null

export const is4xx = response => parseApiResponseStatus(response) === 4

/**
 * to be used client side to parse result from $fetch
 */
export const parseApiResponse = response => {
  // response is already parsed
  const status = parseApiResponseStatus(response)

  if (!status) {
    return response
  }

  if (is4xx(response)) {
    return pick(response, ['status', 'error'])
  }

  // return response if not 4xx
  return response
}
