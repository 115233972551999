import { useState, useCallback, useLayoutEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import IconButton from '@material-ui/core/IconButton'
import PlayArrowRounded from '@material-ui/icons/PlayArrowRounded'
import StopRounded from '@material-ui/icons/StopRounded'
import { useAudio } from '@slc/context'

const useStyles = makeStyles((theme) => ({
  player: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    marginRight: theme.spacing(1),
    border: 'thin solid',
    borderColor: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

export function AudioPlayer({ baseUrl, filename, ...delegated }) {
  const classes = useStyles()
  const [playing, setPlaying] = useState(false)
  const audio = useAudio()

  const onend = useCallback(() => {
    console.log('onEnd', filename)
    setPlaying(false)
  }, [setPlaying, filename])

  useLayoutEffect(
    () => () => {
      if (playing) {
        audio.stop(false)
      }
    },
    [playing, audio],
  )

  return (
    <IconButton
      size="small"
      className={classes.button}
      onClick={() => {
        if (playing) {
          audio.stop()
        } else {
          audio.play(`${baseUrl}${filename}.mp3`, {
            onend,
            onstop: onend,
          })
        }

        setPlaying(!playing)
      }}
      {...delegated}
    >
      {playing ? <StopRounded /> : <PlayArrowRounded />}
    </IconButton>
  )
}

export function AudioPlayerExtract({
  code,
  baseUrl,
  filename,
  label = 'play extract',
  title,
  audio,
}) {
  const classes = useStyles()

  return (
    <div className={classes.player} key={code}>
      {!!audio && (
        <AudioPlayer baseUrl={baseUrl} filename={filename} aria-label={label} />
      )}
      {title && <span>{title}</span>}
    </div>
  )
}

export function MusicExtractPlayer({ code, title, audio }) {
  return (
    <AudioPlayerExtract
      code={code}
      baseUrl={process.env.NEXT_PUBLIC_CATALOG_MUSIC_URL}
      filename={code}
      title={title}
      audio={audio}
    />
  )
}

export function VoiceExtractPlayer({ code, name, audio }) {
  return (
    <AudioPlayerExtract
      code={code}
      baseUrl={process.env.NEXT_PUBLIC_CATALOG_VOICE_URL}
      filename={code}
      title={name}
      audio={audio}
    />
  )
}
