import React from 'react'
import MuiIconButton from '@material-ui/core/IconButton'

export function IconButton({ Icon = null, ...others }) {
  return (
    <MuiIconButton {...others}>
      <Icon />
    </MuiIconButton>
  )
}

export default IconButton
