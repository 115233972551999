import { combineReducers } from 'redux'

import cartReducer, * as cart from './cart'
import wizardReducer, * as wizard from './wizard'
import countryReducer, * as country from './country'
import pricingReducer, * as pricing from './pricing'
import customerReducer, * as customer from './customer'

const rootReducer = combineReducers({
  cart: cartReducer,
  wizard: wizardReducer,
  country: countryReducer,
  pricing: pricingReducer,
  customer: customerReducer
})

export {
  rootReducer as default,
  cart,
  wizard,
  country,
  pricing,
  customer
}
