import React, {
  createContext,
  useState,
  useEffect
} from 'react'

import property from 'lodash/property'
import { customerPricing } from '@slc/api-front'

import { useDispatch, useSelector } from 'react-redux'
import { pricing } from '@slc/store'

const initialState = {
  messages: null,
  options: null,
  pack: null,
  fileSupport: []
}

const PricingContext = createContext(initialState)

export const useCartMessagesPricing = () => useSelector(property('pricing.messages')) || []
export const useCartOptionsPricing = () => useSelector(property('pricing.options')) || { message: [], cart: [] }
export const usePackPricing = () => useSelector(property('pricing.pack')) || []
export const useFileSupportPricing = () => useSelector(property('pricing.fileSupport')) || []

export const PricingProvider = ({ children }) => {
  const [pricingInfos, setPricingInfos] = useState()
  const dispatch = useDispatch()

  useEffect(() => {
    customerPricing()
      .then(setPricingInfos)
      .catch(e => {
        console.log(e)
      })
  }, [])

  useEffect(() => {
    if (pricingInfos) {
      dispatch(pricing.setPricing(pricingInfos))
    }
  }, [pricingInfos])

  return (
    <PricingContext.Provider value={pricingInfos}>{children}</PricingContext.Provider>
  )
}
