export const GSM_DOWNLOAD_MSG_OPTION = 'gsmDownload'
export const CUSTOM_MUSIC_MSG_OPTION = 'customMusic'

export const MESSAGE_OPTIONS = [
  GSM_DOWNLOAD_MSG_OPTION,
  CUSTOM_MUSIC_MSG_OPTION
]

export const MIXING_CART_OPTION = 'mixing'
export const COMMERCIAL_RIGHTS_CART_OPTION = 'commercialRights'
export const DIFFUSION_RIGHTS_CART_OPTION = 'diffusionRights'
export const PROOFREADING_CART_OPTION = 'proofreading'
export const RETAKE_CART_OPTION = 'retake'
export const EXPRESS_DELIVERY_CART_OPTION = 'expressDelivery'
export const THREE_YEAR_ARCHIVE_CART_OPTION = 'threeYearArchive'

export const CART_OPTIONS = [
  MIXING_CART_OPTION,
  COMMERCIAL_RIGHTS_CART_OPTION,
  DIFFUSION_RIGHTS_CART_OPTION,
  PROOFREADING_CART_OPTION,
  RETAKE_CART_OPTION,
  EXPRESS_DELIVERY_CART_OPTION,
  THREE_YEAR_ARCHIVE_CART_OPTION
]
