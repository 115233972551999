export const links = {
  home: '/',
  // shop
  order: '/order',
  orderMusic: '/order/music',
  orderVoice: '/order/voice',
  orderMessage: '/order/message',
  cart: '/order/cart',
  checkout: '/order/checkout',
  waitingPayment: '/order/:oid/payment/waiting',
  creditPayment: '/order/:oid/payment/credit',
  creditPaymentComplete: '/order/:oid/payment/credit/success',
  creditPaymentFailure: '/order/:oid/payment/credit/failure',
  cartPayment: '/order/:oid/payment/card',
  cartPaymentComplete: '/order/:oid/payment/card/success',
  cartPaymentFailure: '/order/:oid/payment/card/failure',
  // static
  about: '/about',
  contact: '/contact',
  pricing: '/pricing',
  // auths
  auth: '/auth',
  signup: '/auth/signup',
  signin: '/api/auth/login',
  signout: '/api/auth/logout',
  // legals
  terms: '/legals/terms-of-use',
  cookies: '/legals/cookie-policy',
  privacy: '/legals/privacy-policy',
  notice: '/legals/notice',
  support: '/support',
  // reseller
  affiliate: '/affiliates'
}
