// standard & semiStandard messages
export const MESSAGE_SELECTION_TEXT = 'text'

// semiStandard message
export const MESSAGE_SELECTION_FIRM = 'firm'

// custom message
export const MESSAGE_SELECTION_BLOCK = 'blocks'
export const MESSAGE_SELECTION_TRANSLATION = 'translation'
export const MESSAGE_SELECTION_ORIGIN = 'origin'
