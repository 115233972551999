import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import MuiTabs from '@material-ui/core/Tabs'
import MuiTab from '@material-ui/core/Tab'

import attempt from 'lodash/attempt'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    border: `thin solid ${theme.palette.divider}`,
  },
  tabs: {
    flex: 1,
    width: '100%',
    borderBottom: `thin solid ${theme.palette.divider}`,
  },
  tabPanel: {
    paddingBlock: theme.spacing(1),
  },
}))

export function tabA11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tab-panel-${index}`,
  }
}

export function TabContent({ children, value, index }) {
  const classes = useStyles()
  const hidden = index !== value

  return (
    <div
      role="tabpanel"
      id={`tab-panel-${index}`}
      aria-labelledby={`tab-${index}`}
      hidden={hidden}
      className={classes.tabPanel}
    >
      {!hidden && children}
    </div>
  )
}

export function TabLayout({
  value,
  onChange,
  tabs,
  tabAriaLabel = '',
  children,
  Tabs = MuiTabs,
  Tab = MuiTab,
}) {
  const classes = useStyles()
  const [tab, setTab] = useState(0)

  useEffect(() => {
    setTab(value)
  }, [value])

  const handleChange = (event, newValue) => {
    setTab(newValue)
    attempt(onChange, newValue)
  }

  return (
    <Paper className={classes.root}>
      <Tabs
        orientation="horizontal"
        variant="scrollable"
        scrollButtons="auto"
        indicatorColor="primary"
        textColor="primary"
        value={tab}
        onChange={handleChange}
        tabarialabel={tabAriaLabel}
        className={classes.tabs}
      >
        {tabs.map((tab, index) => (
          <Tab
            label={tab}
            key={`tab-${index}`}
            {...tabA11yProps(index)}
            variant="outlined"
          />
        ))}
      </Tabs>
      {/* eslint-disable prettier/prettier */}
      {Array.isArray(children)
        ? children.map((child, index) => (
          <TabContent
            key={index}
            value={value}
            index={index}
          >
            {child}
          </TabContent>
          ))
        : { children }}
      {/* eslint-enable prettier/prettier */}
    </Paper>
  )
}

export default TabLayout
