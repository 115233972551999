import { CREDIT_PAYMENT } from '@slc/constants'

import isFunction from 'lodash/isFunction'
import property from 'lodash/property'

export const hasCredit = (choices = []) =>
  choices.map(property('value')).includes(CREDIT_PAYMENT)

export const filter = (paymentTypes = [], predicate = null) =>
  paymentTypes.map((paymentType) => ({
    ...paymentType,
    enabled: isFunction(predicate) ? predicate(paymentType) : true,
  }))

export const activatePaymentTypes = ({
  amount,
  balance = -1,
  choices = [],
}) => {
  if (!hasCredit(choices)) {
    return filter(choices)
  }

  const creditNeeded = amount / 100
  const hasSufficientCredits = balance >= creditNeeded

  return filter(choices, ({ value }) =>
    value === CREDIT_PAYMENT ? hasSufficientCredits : true,
  ).map((choice) => ({
    ...choice,
    ...(choice.value === CREDIT_PAYMENT ? { balance } : {}),
  }))
}
