import { makeStyles } from '@material-ui/core/styles'
import { CHECK_PAYMENT, TRANSFER_PAYMENT } from '@slc/constants'
import { Trans } from '@lingui/macro'
import { Address } from '@slc/ui'

const useStyles = makeStyles((theme) => ({
  field: {
    fontFamily: 'courier',
  },
}))

export function OrderPaymentInstructions({ payment, instructions }) {
  const classes = useStyles()

  switch (payment) {
    case CHECK_PAYMENT:
      return <CheckInstructions instructions={instructions} classes={classes} />

    case TRANSFER_PAYMENT:
      return (
        <TransferInstructions instructions={instructions} classes={classes} />
      )

    default:
      return null
  }
}

export function CheckInstructions({ instructions, classes }) {
  return (
    <dl>
      <dt>
        <Trans id="ui.order.thankyou.check.instructions.address" />
      </dt>
      <dd className={classes.field}>
        <Address address={instructions.address} />
      </dd>
    </dl>
  )
}

export function TransferInstructions({ instructions, classes }) {
  const { name, iban, bic, ...address } = instructions

  return (
    <dl>
      <dt>
        <Trans id="ui.order.thankyou.transfer.instructions.bank.name" />
      </dt>
      <dd className={classes.field}>{name}</dd>
      <dt>
        <Trans id="ui.order.thankyou.transfer.instructions.bank.iban" />
      </dt>
      <dd className={classes.field}>{iban}</dd>
      <dt>
        <Trans id="ui.order.thankyou.transfer.instructions.bank.bic" />
      </dt>
      <dd className={classes.field}>{bic}</dd>
      <dt>
        <Trans id="ui.order.thankyou.transfer.instructions.bank.address" />
      </dt>
      <dd className={classes.field}>
        <Address address={address} />
      </dd>
    </dl>
  )
}
