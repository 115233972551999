import React, { forwardRef, useState, useEffect } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import attempt from 'lodash/attempt'
import get from 'lodash/get'
import isRegExp from 'lodash/isRegExp'

const useStyles = makeStyles((theme) => ({
  formControl: {},
}))

export function FormInput(
  {
    className = '',
    name,
    label,
    controlled = false,
    defaultValue = undefined,
    value,
    type = 'text',
    required = false,
    error = false,
    errorText = '',
    helperText = '',
    onChange,
    disabled = false,
    fullWidth = false,
    multiline = false,
    pattern = false,
    ...others
  },
  ref,
) {
  const classes = useStyles()
  const [state, setState] = useState({
    value: controlled ? value : defaultValue,
  })

  const [hasError, setError] = useState(error)

  useEffect(() => {
    setError(error)
  }, [error])

  const isNumber = type === 'number'

  const handleChange = (event) => {
    const changedValue = get(event, 'target.value', event)

    if (isNumber && isNaN(changedValue)) {
      return
    }

    const formattedValue = isNumber ? parseInt(changedValue, 10) : changedValue

    setState({ ...state, value: formattedValue })

    if (pattern && isRegExp(pattern)) {
      setError(!pattern.test(formattedValue))
    }

    attempt(onChange, formattedValue)
  }

  return (
    <FormControl
      className={clsx(classes.formControl, {
        [className]: className !== undefined,
      })}
      fullWidth={fullWidth}
    >
      <TextField
        ref={ref}
        id={name}
        label={label}
        type={type}
        defaultValue={state.value}
        onChange={handleChange}
        variant="outlined"
        required={required}
        error={hasError !== false}
        helperText={hasError ? error || errorText : helperText}
        disabled={disabled}
        fullWidth={fullWidth}
        multiline={multiline}
        {...others}
      />
    </FormControl>
  )
}

export default forwardRef(FormInput)
