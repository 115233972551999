import React from 'react'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import Button from './Button'

import { Link } from '../Link'

export function LinkButton({
  href,
  onClick,
  children,
  clientSide = true,
  ...others
}) {
  if (href) {
    return (
      <Link href={href} clientSide={clientSide}>
        <Button endIcon={<NavigateNextIcon />} {...others}>
          {children}
        </Button>
      </Link>
    )
  }

  if (onClick) {
    others.onClick = onClick
  }

  return (
    <Button endIcon={<NavigateNextIcon />} {...others}>
      {children}
    </Button>
  )
}

export default LinkButton
