import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import MuiTab from '@material-ui/core/Tab'

export const Tab = withStyles((theme) => ({
  root: {
    maxWidth: '20%',
    flex: 1,

    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
    },
    '&:$selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    '&:focus': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
    },
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}))((props) => <MuiTab disableRipple {...props} />)
