import _defineProperty from "/vercel/path0/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { links, getAppLinkHref } from '@slc/app-links';
var app = links.app;

var l = function l(link) {
  return _objectSpread(_objectSpread({}, link), {}, {
    href: getAppLinkHref(link)
  });
};

export var footerMenus = [[l(app.home), l(app.contact), l(app.auth)], [l(app.about), l(app.pricing) // app.terms
]];
export var topMenu = [l(app.support), l(app.affiliate)];
export var mainMenu = [l(app.home), l(app.order), l(app.pricing), l(app.about), l(app.contact)];
export var secondaryMenu = [l(app.customer), l(app.cart)];
export var bottomMenu = [l(app.terms), l(app.privacy), l(app.cookies), l(app.notice)];