import React, { forwardRef, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'

import attempt from 'lodash/attempt'
import isString from 'lodash/isString'
import isFunction from 'lodash/isFunction'
import find from 'lodash/find'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import NoSsr from '@material-ui/core/NoSsr'

import { createFilterOptions } from '@material-ui/lab/Autocomplete'

import dynamic from 'next/dynamic'

const Autocomplete = dynamic(() => import('@material-ui/lab/Autocomplete'), {
  ssr: false,
})

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
}))

const defaultFilterOptions = createFilterOptions({
  matchFrom: 'start',
  stringify: (option) => option.value,
})

const defaultGetOptionLabel = (options) => (option) => {
  if (isEmpty(option)) {
    return ''
  }

  return get(
    isString(option) ? find(options, { value: option }) : option,
    'label',
    '',
  )
}
const defaultGetOptionSelected = (options) => (option, value) => {
  const selection = isString(value) ? find(options, { value }) : value

  if (isEmpty(selection)) {
    return false
  }

  return option.value === selection.value
}

const defaultGetSelectionValue = (options) => (value) =>
  isString(value) ? value : value.value

const filterObject = (object) => {
  const filtered = {}

  Object.keys(object).map((key) => {
    if (!isNil(object[key])) {
      filtered[key] = object[key]
    }
  })

  return filtered
}

export function FormInputAutoComplete(
  {
    name,
    label,
    choices = [],
    controlled = false,
    defaultValue = undefined,
    value,
    required = false,
    error = false,
    onChange,
    //
    getSelectionValue,
    // Autocomplete options
    fetchOptions = undefined,
    filterOptions,
    getOptionLabel,
    getOptionSelected,
    autoComplete = false,
    opened = false,
    loading = false,
    onInputChange = undefined,
    renderOption = undefined,
  },
  ref,
) {
  const classes = useStyles()
  const [state, setState] = useState({
    value: controlled ? value : defaultValue,
  })
  console.log('Autocomplete', value, controlled, state)

  const [open, setOpen] = useState(opened)
  const [options, setOptions] = useState(choices)

  const hasFetcher = fetchOptions && isFunction(fetchOptions)
  const isLoading = loading || (hasFetcher && open && options.length === 0)

  useEffect(() => {
    if (choices !== options) {
      setOptions(choices)
    }
  }, [choices, options])

  useEffect(() => {
    let active = true

    if (!hasFetcher || !isLoading) {
      return undefined
    }

    ;(async () => {
      const data = await fetchOptions()

      if (active) {
        setOptions(data)
      }
    })()

    return () => {
      active = false
    }
  }, [hasFetcher, isLoading, options, fetchOptions])

  useEffect(() => {
    if (!hasFetcher) {
      return undefined
    }

    if (!open) {
      setOptions([])
    }
  }, [hasFetcher, open, options])

  const handleChange = (event, value, reason) => {
    const selection = (getSelectionValue || defaultGetSelectionValue(options))(
      value,
      reason,
    )

    setState({
      ...state,
      value: selection,
    })
    attempt(onChange, selection)
  }

  const props = filterObject({
    onInputChange,
    // renderOption
  })

  return (
    <FormControl className={classes.formControl} fullWidth>
      <NoSsr defer>
        <Autocomplete
          ref={ref}
          id={name}
          value={state.value}
          onChange={handleChange}
          classes={{
            option: classes.option,
          }}
          open={open || opened}
          onOpen={() => {
            setOpen(true)
          }}
          onClose={() => {
            setOpen(false)
          }}
          noOptionsText="..."
          options={options}
          filterOptions={filterOptions || defaultFilterOptions}
          getOptionLabel={getOptionLabel || defaultGetOptionLabel(options)}
          getOptionSelected={
            getOptionSelected || defaultGetOptionSelected(options)
          }
          autoComplete={autoComplete}
          includeInputInList
          filterSelectedOptions
          clearOnEscape
          clearOnBlur
          autoHighlight
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              variant="outlined"
              required={required}
              error={!!error}
              helperText={error || ''}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          {...props}
        />
      </NoSsr>
    </FormControl>
  )
}

export default forwardRef(FormInputAutoComplete)
