export const TOPUP_STATEMENT_TYPE = 'topup'
export const ORDER_STATEMENT_TYPE = 'order'
export const REFUND_STATEMENT_TYPE = 'refund'

export const STATEMENT_TYPES = [
  TOPUP_STATEMENT_TYPE,
  ORDER_STATEMENT_TYPE,
  REFUND_STATEMENT_TYPE
]


export const STATEMENT_TYPE = 'statement'
export const INVOICE_TYPE = 'invoice'
