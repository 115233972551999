import React, { forwardRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import attempt from 'lodash/attempt'

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: 0,
    width: '100%',
    backgroundColor: '#fff',
  },
}))

export function FormTextArea(
  {
    name,
    label,
    controlled = false,
    defaultValue = undefined,
    value = '',
    required = false,
    error = false,
    helperText = '',
    onChange,
    disabled = false,
    fullWidth = true,
    ...others
  },
  ref,
) {
  const classes = useStyles()
  const [state, setState] = useState({
    value: controlled ? value : defaultValue,
  })

  const handleChange = (event) => {
    const { value } = event.target
    setState({
      ...state,
      value: controlled ? value : value.value,
    })
    attempt(onChange, value)
  }

  return (
    <FormControl className={classes.formControl} fullWidth={fullWidth}>
      <TextField
        ref={ref}
        id={name}
        label={label}
        defaultValue={state.value}
        onChange={handleChange}
        variant="outlined"
        required={required}
        error={!!error}
        helperText={error || helperText}
        disabled={disabled}
        fullWidth={fullWidth}
        multiline
        rows={4}
        rowsMax={10}
        {...others}
      />
    </FormControl>
  )
}

export default forwardRef(FormTextArea)
