import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import { FormFooter as DefaultFooter } from './Footer'

const useStyles = makeStyles((theme) => ({
  form: {
    flex: 1,
    maxWidth: 800,
  },
}))

export function Form({
  onSubmit,
  onCancel,
  enabled = true,
  displayCancel = false,
  Footer = DefaultFooter,
  children,
}) {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)

  return (
    <form
      autoComplete="off"
      autoCapitalize="off"
      noValidate
      className={classes.form}
      onSubmit={async (e) => {
        e.nativeEvent.stopImmediatePropagation()
        e.preventDefault()

        setLoading(true)

        return onSubmit(e)
          .then((v) => {
            setLoading(false)
            return v
          })
          .catch((e) => {
            console.log(e)
            setLoading(false)
            throw e
          })
      }}
    >
      <Grid container spacing={3} padding={3}>
        <Grid item xs={12}>
          {children}
        </Grid>
        {Footer && (
          <Grid item xs={12} className={classes.footer}>
            <Footer
              onCancel={onCancel}
              displayCancel={displayCancel}
              enabled={enabled && !loading}
              loading={loading}
            />
          </Grid>
        )}
      </Grid>
    </form>
  )
}
