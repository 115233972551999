import { Elements } from '@stripe/react-stripe-js'
import { getStripe } from '@slc/stripe-utils'

import { CardPaymentForm } from './CardForm'
import { CardProvider } from './CardContext'

export function CardPayment({
  getPaymentIntent,
  onOrderCompleted,
  onOrderFailure,
}) {
  return (
    <Elements stripe={getStripe()}>
      <CardProvider>
        <CardPaymentForm
          getPaymentIntent={getPaymentIntent}
          onOrderCompleted={onOrderCompleted}
          onOrderFailure={onOrderFailure}
        />
      </CardProvider>
    </Elements>
  )
}
